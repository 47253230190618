import styles from "./BuyCreditsButton.module.css";
import { SignatureElec } from "../UI/Icons";
import { currencyFormatter } from "../../api/Functions";
import ButtonCard from "../UI/ButtonCard";
import { TextOneOrMany } from "../../utils";
import { SIGNATURE_PRICE } from "../../models/signatureElectronique";

export default function BuyCreditsButton({ price, onClick }) {
  const credits = parseFloat(price.credits);
  const priceAmount = price.unit_amount / 100;
  const discount = Math.round((1 - priceAmount / credits) * 100);
  const nbSignature = Math.round(credits / SIGNATURE_PRICE);
  const subText =
    nbSignature > 1 &&
    `Soit ${currencyFormatter(priceAmount / nbSignature)} TTC la signature`;
  return (
    <ButtonCard
      onClick={() => onClick(price.id)}
      padding={"none"}
      className={styles.parent}
      type={"button"}
    >
      {discount > 0 && <div className={styles.ribbon}>-{discount}%</div>}
      <p className={styles.header}>
        {nbSignature}{" "}
        <TextOneOrMany
          many={"signatures électroniques"}
          one={"signature électronique"}
          number={nbSignature}
        />
      </p>
      <div className={styles.body}>
        <div className={styles.nbSignatures}>
          <SignatureElec /> <p>x {nbSignature}</p>
        </div>
        <div className={styles.priceSignatures}>
          <p>{currencyFormatter(priceAmount)}</p>
          <p>TTC</p>
        </div>
      </div>
      <p className={"secondaryText"}>{subText}</p>
      <div className={styles.footer}>Acheter</div>
    </ButtonCard>
  );
}
