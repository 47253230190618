import { Form, Formik } from "formik";
import Select from "../UI/Select";
import { DOCUMENT_LIST, FileStatus } from "../../models/documents";
import TextInput from "../UI/TextInput";
import Radio from "../UI/Radio";
import RadioGroup from "../UI/RadioGroup";
import FormUpload from "./FormUpload";
import { sanitizeValues } from "../../models/utils";
import useTenants from "../../hooks/use-tenants";
import { produce } from "immer";
import useFiles from "../../hooks/use-files";
import { useEffect, useState } from "react";
import Card from "../UI/Card";
import { handleAPIError } from "../../utils";
import Checkbox from "../UI/Checkbox";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import TenantSelect from "../Tenant/TenantSelect";

export default function DocumentsFormUpload({
  property,
  tenant,
  isBailleur,
  isLocataire,
  file,
  closeDocumentModal,
  typeDocument,
}) {
  const { updateTenant } = useTenants();
  const { updateFile, addFiles } = useFiles({
    tenantId: tenant?.id,
    propertyId: property?.id,
    isTenant: isLocataire,
  });
  const edit = !!file;
  const [error, setError] = useState("");
  const [validationSchema, setValidationSchema] = useState("");

  useEffect(() => {
    if (typeDocument) {
      const document = DOCUMENT_LIST.find((doc) => doc.tag === typeDocument);
      if (document) {
        setValidationSchema(document.validationSchema);
      }
    }
  }, [typeDocument]);

  async function submitHandler(values, { resetForm }) {
    let sanitizedValues = sanitizeValues(values);
    const newFiles = produce(sanitizedValues.files, (draft) => {
      draft.forEach((file) => {
        file.valid_until = sanitizedValues.valid_until;
        file.shared = sanitizedValues.shared;
        file.status = sanitizedValues.status;
        file.tag = sanitizedValues.tag;
        if (sanitizedValues.tag === "quittance") {
          file.meta = {
            date_quittance: sanitizedValues.date_quittance,
          };
        }
        if (sanitizedValues.filename) {
          file.filename = sanitizedValues.filename;
        }
      });
    });

    try {
      if (!isLocataire) {
        // Pour un bail, la date signature fait partie du tenant
        // Update tenant si le form a un field date_signature
        if (sanitizedValues?.date_signature) {
          await updateTenant(tenant.id, {
            date_signature: sanitizedValues.date_signature,
          });
        }
      }
      if (edit) {
        await updateFile(file.id, newFiles[0]);
      } else {
        await addFiles(newFiles);
      }
      closeDocumentModal();
      resetForm();
      setError("");
    } catch (error) {
      setError(handleAPIError(error));
    }
  }

  const initialValues = {
    id_tenant: file?.id_tenant ?? tenant?.id ?? "",
    id_bien: file?.id_bien ?? property?.id ?? "",
    status:
      file?.status?.toString() ??
      (isLocataire
        ? FileStatus.aValider.status.toString()
        : FileStatus.valide.status.toString()),
    files: file ? [file] : [],
    tag: file?.tag || (typeDocument ?? ""),
    valid_until: file?.valid_until || "",
    date_signature: tenant?.date_signature ?? "",
    shared: file?.shared || false,
    filename: file?.filename || "",
    date_quittance: "",
  };

  if (file?.meta?.date_quittance) {
    initialValues.date_quittance = file?.meta?.date_quittance;
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={submitHandler}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, setFieldValue }) => {
        const document = DOCUMENT_LIST.find(
          (document) => values.tag === document.tag,
        );
        function changeDocument(e) {
          const document = DOCUMENT_LIST.find(
            (document) => e.target.value === document.tag,
          );
          document.fields.forEach((field, i) => {
            field.type === "checkbox" &&
              field.checked === true &&
              setFieldValue(field.name, true);
          });
          setValidationSchema(document?.validationSchema);
          handleChange(e);
        }

        return (
          <Form>
            <h2 className={"margin0"}>
              {edit ? "Modifier" : "Ajouter"} un document
            </h2>
            <Select
              name="tag"
              disabled={edit}
              onChange={(e) => changeDocument(e)}
            >
              <option value="">Sélectionnez le type de document</option>
              {(isBailleur || property) &&
                DOCUMENT_LIST.filter((doc) =>
                  property ? doc.vueProperty : doc.vueBailleur,
                ).map((option, i) => (
                  <option key={i} value={option.tag}>
                    {option.nom}
                  </option>
                ))}

              {isLocataire &&
                DOCUMENT_LIST.filter((doc) => doc.vueLocataire).map(
                  (option, i) => (
                    <option key={i} value={option.tag}>
                      {option.nom}
                    </option>
                  ),
                )}
            </Select>
            {(isBailleur || property) && (
              <TextInput
                type="text"
                name="filename"
                label="Nom du document"
                placeholder="règlement de copro"
              />
            )}
            {document?.fields.map((field, i) =>
              field.type === "checkbox" ? (
                <Checkbox key={i} name={field.name}>
                  {field.label}
                </Checkbox>
              ) : (
                <TextInput
                  type={field.type}
                  name={field.name}
                  placeholder={field.label}
                  label={field.label}
                  key={i}
                />
              ),
            )}
            {!property && (
              <>
                {document?.addLocataire && !initialValues.id_tenant && (
                  <TenantSelect name="id_tenant" withArchivedTenants />
                )}
                {document?.needValidation && isBailleur && (
                  <RadioGroup className={"marginT10"}>
                    <Radio
                      name="status"
                      value={FileStatus.valide.status.toString()}
                      label="Validé"
                    />
                    <Radio
                      name="status"
                      value={FileStatus.aValider.status.toString()}
                      label="A valider"
                    />
                  </RadioGroup>
                )}
              </>
            )}
            <FormUpload
              tag={document?.tag}
              valid_until={values.valid_until}
              status={values.status}
              edit={edit}
            />

            <div className={"text-right"}>
              <FormikSubmitButton>Valider</FormikSubmitButton>
            </div>
            {error && (
              <Card
                type={"error"}
                className={"padding10 text-center marginT10"}
              >
                {error}
              </Card>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
