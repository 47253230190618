import { TenantStatus } from "../../models/tenant";
import Select from "../UI/Select";
import useTenants from "../../hooks/use-tenants";

export default function TenantSelect({
  filterCallback,
  withArchivedTenants,
  ...rest
}) {
  const { tenants } = useTenants();
  const filter = filterCallback ?? (() => true);

  const filteredTenants = filterCallback ? tenants.filter(filter) : tenants;

  function tenantToOption(tenant) {
    return (
      <option value={tenant.id} key={tenant.id}>
        {tenant.profile_nom.nom_profile}
      </option>
    );
  }

  return (
    <Select {...rest}>
      <option value={""}>Choisissez un locataire</option>
      <option disabled>=== Locataires actifs ===</option>
      {filteredTenants
        .filter((tenant) => tenant.status === TenantStatus.Actif.status)
        .map(tenantToOption)}
      {withArchivedTenants && (
        <>
          <option disabled>=== Locataires archivés ===</option>
          {filteredTenants
            .filter((tenant) => tenant.status === TenantStatus.Archive.status)
            .map(tenantToOption)}
        </>
      )}
    </Select>
  );
}
