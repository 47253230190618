import StyledForm from "../../UI/StyledForm";
import { CalendarCheck, TypeBail, ZoneTendue } from "../../UI/Icons";
import TextInput from "../../UI/TextInput";
import Checkbox from "../../UI/Checkbox";
import Select from "../../UI/Select";
import Card from "../../UI/Card";

export default function TenantLeaseForm() {
  return (
    <>
      <StyledForm
        formElement={<TextInput type="date" name={"debut"} />}
        label="Date de début du bail"
        icon={<CalendarCheck stroke={"#9D9D9D"} />}
        mandatory
      />
      <StyledForm
        formElement={
          <>
            <TextInput type="date" name={"fin"} />
            <Checkbox className={"marginB10"} name={"preavis"}>
              Préavis déposé
            </Checkbox>
          </>
        }
        label="Date d'échéance du bail"
        icon={<CalendarCheck stroke={"#9D9D9D"} />}
        mandatory
      />
      <StyledForm
        formElement={
          <>
            <TextInput
              type="number"
              name="loyer_hc"
              placeholder="Loyer hors charge"
              step={"0.01"}
            />
            <Checkbox className={"marginB10"} name={"tva"}>
              locataire assujetti à la TVA
            </Checkbox>
          </>
        }
        label="Loyer hors charge (hors complément de loyer si zone tendue)"
        icon={<ZoneTendue />}
        mandatory
      />
      <StyledForm
        formElement={
          <TextInput
            type="number"
            name="charges"
            placeholder="Charges"
            step={"0.01"}
          />
        }
        label="Charges"
        icon={<ZoneTendue />}
        mandatory
      />

      <StyledForm
        formElement={
          <TextInput
            type="number"
            name="deposit"
            placeholder="Montant du dépôt de garantie"
            step={"0.01"}
          />
        }
        label="Montant du dépôt de garantie"
        icon={<ZoneTendue />}
        mandatory
      />

      <StyledForm
        formElement={
          <div className={"flex wrap gap10"}>
            <TextInput
              type="number"
              name="day_payment"
              placeholder="Jour du mois auquel le loyer doit être payé"
            />
            <Select name="payment_recurrence" className={""}>
              <option value="1">Mensuel</option>
              <option value="3">Trimestriel</option>
              <option value="12">Annuel</option>
            </Select>
          </div>
        }
        label="Jour du mois auquel le loyer doit être payé"
        icon={<CalendarCheck />}
        mandatory
      />

      <StyledForm
        formElement={<TextInput type="date" name="last_revision_date" />}
        label="Date de la dernière révision de loyer"
        icon={<CalendarCheck />}
      />

      <StyledForm
        formElement={<TextInput type="date" name="date_signature" />}
        label="Date de signature du bail"
        icon={<TypeBail />}
        mandatory
      />

      <StyledForm
        formElement={
          <div className="flex gap10 wrap">
            <TextInput type="text" name="irl_trimestre" label={"Trimestre"} />
            <TextInput type="number" name="irl_annee" label={"Année"} />
            <TextInput
              type="number"
              name="irl_indice"
              label={"Indice"}
              step={"0.01"}
            />
          </div>
        }
        label="Indice de référence des loyers"
        icon={<TypeBail />}
      />
      <Card type={"warning"} className={"marginT20 marginB20"} padding={"sm"}>
        <p>
          Les informations modifiées ici ne sont pas répercutées dans le bail
          généré. Pour modifier le bail, vous devez aller dans l'onglet "Action
          / courrier" du locataire, puis cliquer sur "Regénérer le bail".
        </p>
        <p className={"marginT5"}>
          Si vous avez modifié les dates d'entrée ou de sortie, rendez-vous dans
          l'onglet "historique des paiements" pour mettre à jour les prorata de
          début et/ou de fin.
        </p>
      </Card>
    </>
  );
}
