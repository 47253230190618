import Menu from "../components/Menu/Menu.js";
import MonCompte from "../components/Account/MonCompte";
import Tabs from "../components/UI/Tabs";
import Page from "../components/UI/Page";
import Factures from "../components/Account/Factures";
import useAccount from "../hooks/use-account";
import Mandataire from "../components/Account/Mandataire";
import MailLogs from "../components/Account/MailLogs";
import Partenaires from "../components/Account/Partenaires";

export default function MonComptePage() {
  const { account } = useAccount();
  return (
    <>
      <Menu isBailleur />
      <Page
        header={<h2 className={"margin0 padding10"}>Mon compte</h2>}
        body={
          <Tabs id="monCompte">
            <div className="container bg-blue paddingT10 border-blue">
              <div className="content">
                <Tabs.TabList>
                  <Tabs.Tab panelId="informations">
                    Informations générales
                  </Tabs.Tab>
                  <Tabs.Tab panelId="factures">Facturation</Tabs.Tab>
                  {account.home === "/pro" && (
                    <Tabs.Tab panelId="mandataire">Mandataire</Tabs.Tab>
                  )}
                  <Tabs.Tab panelId="logs">Mail logs</Tabs.Tab>
                  {account.partner_codes.length > 0 && (
                    <Tabs.Tab panelId="partenaires">Partenaires</Tabs.Tab>
                  )}
                </Tabs.TabList>
              </div>
            </div>
            <div className="blueSpace"></div>
            <div className="content">
              <Tabs.TabPanel id="informations">
                <MonCompte />
              </Tabs.TabPanel>
              <Tabs.TabPanel id="factures">
                <Factures />
              </Tabs.TabPanel>
              <Tabs.TabPanel id="mandataire">
                <Mandataire />
              </Tabs.TabPanel>
              <Tabs.TabPanel id="logs">
                <MailLogs />
              </Tabs.TabPanel>
              {account.partner_codes.length > 0 && (
                <Tabs.TabPanel id="partenaires">
                  <Partenaires partnerCodes={account.partner_codes} />
                </Tabs.TabPanel>
              )}
            </div>
          </Tabs>
        }
      />
    </>
  );
}
