import Card from "../UI/Card";
import CardToggle from "../UI/CardToggle";
import moment from "moment";
import Status from "../UI/Status";
import { MailLogsStatus } from "../../models/mailLogs";
import useMailLogs from "../../hooks/use-mail-logs";

export default function MailLogs() {
  const { mailLogs } = useMailLogs();
  return (
    <Card>
      {mailLogs.map((mail) => (
        <CardToggle
          key={mail.id}
          title={
            <div
              className={
                "flex align-items-center primaryText space-between w-100"
              }
            >
              <div>
                {mail.subject}
                {mail.sent_at && (
                  <>
                    {" "}
                    - Envoyé le{" "}
                    {moment(mail.sent_at).format("DD/MM/YYYY à HH:mm")}
                  </>
                )}
              </div>
              <div>
                {mail.status === MailLogsStatus.SENT && (
                  <Status color={"green"} text={"Envoyé"} />
                )}
                {mail.status === MailLogsStatus.PENDING && (
                  <Status color={"yellow"} text={"En cours d'envoi"} />
                )}
                {mail.status === MailLogsStatus.ERROR && (
                  <Status color={"red"} text={"Erreur"} />
                )}
              </div>
            </div>
          }
        >
          <p>
            <strong>Destinataire</strong> : {mail.destinataire}
          </p>
          <p>
            <strong>Cc</strong> : {mail.cc}
          </p>
          <pre className={"pre-whitespace"}>{mail.plain}</pre>
        </CardToggle>
      ))}
    </Card>
  );
}
