import useResources from "./use-resources";
import { findAndUpdate } from "../utils";

const ROOT_PATH = "files";

export default function useFiles({ tenantId, propertyId, isTenant = false }) {
  const {
    resources: files,
    error,
    isLoading,
    update,
    del,
    mutate,
    axios,
  } = useResources(ROOT_PATH, {
    params: { id_tenant: tenantId, is_tenant: isTenant, id_bien: propertyId },
  });

  function addFiles(files) {
    return mutate(
      async (currentFiles) => {
        const response = await axios.post(ROOT_PATH, files, {
          params: {
            id_tenant: tenantId,
            is_tenant: isTenant,
            id_bien: propertyId,
          },
        });
        return [...currentFiles, ...response.data];
      },
      { revalidate: false },
    );
  }

  async function sendToLaPoste(fileId, values) {
    let newResource;
    await mutate(
      async (current) => {
        const response = await axios.post(`/laposte/send`, {
          id_file: fileId,
          ...values,
        });
        newResource = response.data;
        return findAndUpdate(
          current,
          (resource) => resource.id === fileId,
          newResource,
        );
      },
      { revalidate: false },
    );
    return newResource;
  }
  async function sendToElectronicSigning(fileId) {
    let newResource;
    await mutate(
      async (current) => {
        const response = await axios.get(`oodrive/sign/${fileId}`);
        newResource = response.data;
        return findAndUpdate(
          current,
          (resource) => resource.id === fileId,
          newResource,
        );
      },
      { revalidate: false },
    );
    return newResource;
  }
  async function cancelElectronicSigning(fileId) {
    let newResource;
    await mutate(
      async (current) => {
        const response = await axios.get(`oodrive/${fileId}/cancel`);
        newResource = response.data;
        return findAndUpdate(
          current,
          (resource) => resource.id === fileId,
          newResource,
        );
      },
      { revalidate: false },
    );
    return newResource;
  }
  async function relanceSignataire(fileId, signataireId) {
    await axios.get(`oodrive/${fileId}/relance/${signataireId}`);
  }

  return {
    files,
    error,
    isLoading,
    addFiles,
    deleteFile: del,
    updateFile: update,
    sendToElectronicSigning,
    cancelElectronicSigning,
    sendToLaPoste,
    relanceSignataire,
  };
}
