import { useParams } from "react-router-dom";
import LocataireShow from "../../components/Locataire/LocataireShow";
import useTenants from "../../hooks/use-tenants";

function LocataireShowPage() {
  const { tenants } = useTenants({ isLocataire: true });
  const { tenantId } = useParams();
  const tenant = tenants.find((tenant) => tenant.id === parseInt(tenantId));
  return (
    <div className={"container"}>
      <div className={"content marginT20"}>
        <LocataireShow tenant={tenant} />
      </div>
    </div>
  );
}

export default LocataireShowPage;
