import styles from "./EdlIntroduction.module.css";
import CardStep from "../UI/CardStep";
import { BuildEdl, EtatItem, EtatPiece, Photo } from "../UI/Icons";
import Button from "../UI/Button";
import Tabs from "../UI/Tabs";

export default function EdlIntroduction() {
  const { selectNextTab } = Tabs.useActiveTab();

  return (
    <div className={styles.container}>
      <h2 className={"text-center"}>
        Réalisez vos états des lieux en 4 étapes en toute simplicité !
      </h2>
      <CardStep
        icon={<BuildEdl />}
        step={"Etape 1"}
        title={"Construire l'état des lieux"}
        text={
          <>
            Les pièces que vous retrouvez sont celles sélectionnées lors de la
            création de votre bien. Vous pouvez les modifier ou en ajouter
            depuis le bien concerné dans la partie "Description du bien". Vous
            pouvez aussi créer manuellement une pièce.
          </>
        }
      />
      <CardStep
        icon={<EtatPiece />}
        step={"Etape 2"}
        title={"Compléter l’état des pièces"}
        text={
          <>
            <p className={"secondaryText"}>
              Pour chaque pièce, vous devrez renseigner :
            </p>
            <ul>
              <li>
                <span className={"primaryBlue"}>L’état du bâti</span> (sol, mur,
                plafonds, fenêtre, etc.)
              </li>
              <li>
                <span className={"primaryBlue"}>L’état des équipements</span>{" "}
                (douches, WC, éléments de cuisine, etc.)
              </li>
              <li>
                <span className={"primaryBlue"}>L’état du mobilier</span> (pour
                les locations en meublé)
              </li>
            </ul>
          </>
        }
      />
      <CardStep
        icon={<EtatItem />}
        step={"Etape 3"}
        title={"Renseigner l’état de chaque élément"}
        text={
          <>
            Nous avons pré-paramétré les pièces avec les éléments les plus
            courants. Si ces derniers ne sont pas présents, il faut les
            supprimer. Vous pouvez ajouter de nouveaux éléments. Vous pourrez
            renseigner la composition des revêtements, les dégradations
            éventuelles, etc.
          </>
        }
      />
      <CardStep
        icon={<Photo />}
        step={"Etape 4"}
        title={"Ajouter des photos"}
        text={
          <>
            Rendez incontestables vos état des lieux ! Afin de prévenir tout
            litige et éviter toute contestation, vous pouvez ajouter des photos
            pour chaque pièce. Elles seront intégrées dans le document d’état
            des lieux qui sera généré à la fin.
          </>
        }
      />
      <div className={"marginT20 text-right"}>
        <Button onClick={() => selectNextTab()}>Suivant</Button>
      </div>
    </div>
  );
}
