import useProcesses from "../../hooks/use-processes";
import useTenants from "../../hooks/use-tenants";
import ButtonCard from "../UI/ButtonCard";
import { useState } from "react";
import Button from "../UI/Button";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import Process from "./Process/Process";
import { ProcessDepartIcon } from "../UI/Icons";
import Card from "../UI/Card";
import { ProcessType } from "../../utils/constants/processes";
import ProcessChoice from "./ProcessChoice";

export default function Processes({ onFinish, tenantId = "" }) {
  const { activeProcesses, createProcess, updateProcess } = useProcesses();
  const { tenants } = useTenants();
  const [selectedProcess, setSelectedProcess] = useState(null);
  async function submitHandler(values, { resetForm }) {
    try {
      const response = await createProcess({
        is_active: true,
        entries: {},
        ...values,
      });
      setSelectedProcess(response);
      resetForm();
    } catch (error) {
      console.error(error);
    }
  }

  async function finishProcessHandler(processId) {
    await updateProcess(processId, { is_active: false });
    onFinish();
  }

  return (
    <div>
      {/* If no process is selected, display the list of processes*/}
      {!selectedProcess && (
        <>
          <img src={"/Processus.png"} alt={"processus"} className={"center"} />
          <h2 className={"text-center"}>Processus</h2>
          <Formik
            initialValues={{ id_tenant: tenantId, type: null }}
            enableReinitialize={true}
            validationSchema={object({
              id_tenant: string().required(" "),
              type: string().required(" "),
            })}
            onSubmit={submitHandler}
          >
            <Form className={"flex wrap gap10 align-items-center marginT20"}>
              <ProcessChoice
                processType={ProcessType.DEPART}
                text={"Départ initié par le locataire"}
                icon={<ProcessDepartIcon />}
              />
              <ProcessChoice
                processType={ProcessType.DEPART_BAILLEUR}
                text={"Départ initié par le bailleur"}
                icon={<ProcessDepartIcon />}
              />
            </Form>
          </Formik>
          <h2 className={"text-center"}>Processus en cours</h2>
          {activeProcesses.length > 0 ? (
            <>
              {activeProcesses.map((proc) => {
                const tenant = tenants.find((t) => t.id === proc.id_tenant);
                if (!tenant) return null;
                return (
                  <ButtonCard
                    onClick={() => setSelectedProcess(proc)}
                    key={proc.id}
                    className={"w-100 marginB10 padding10"}
                  >
                    {tenant.profile_nom.nom_profile} - {proc.type}
                  </ButtonCard>
                );
              })}
            </>
          ) : (
            <Card className={"marginT10 text-center"}>
              Aucun processus en cours
            </Card>
          )}
        </>
      )}
      {selectedProcess && (
        <>
          <Button onClick={() => setSelectedProcess(null)}>
            Retour à la liste des processus
          </Button>
          <Process process={selectedProcess} onFinish={finishProcessHandler} />
        </>
      )}
    </div>
  );
}
