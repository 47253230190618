import CardNumber from "../UI/CardNumber";
import style from "./Dashboard.module.css";
import {
  Argent,
  ArrowDownRight,
  Check,
  Documents,
  Dossier,
  FirstSteps,
  HousePlus,
  HousePrivatif,
  Interrogation,
  Message,
  People,
  Process,
  Quittance,
  Raccourcis,
  RoundedQuestion,
} from "../UI/Icons";
import moment from "moment/moment";
import { TenantStatus } from "../../models/tenant";
import { Link, useNavigate } from "react-router-dom";
import useProperties from "../../hooks/use-properties";
import Card from "../UI/Card";
import IconAndText from "../UI/IconAndText";
import Button from "../UI/Button";
import Page from "../UI/Page";
import { useModal } from "../../hooks/use-modal";
import QuittanceForm from "../Tenant/Forms/QuittanceForm";
import LoyersShow from "./LoyersShow";
import useTenants from "../../hooks/use-tenants";
import Tabs from "../UI/Tabs";
import ButtonCard from "../UI/ButtonCard";
import { PropertyStatus } from "../../models/property";
import { classnames, TextOneOrMany } from "../../utils";
import JoyrideQalimo from "../JoyrideQalimo/JoyrideQalimo";
import useProfileNoms from "../../hooks/use-profile-noms";
import SelectPropertyTenantAction from "../UI/SelectPropertyTenantAction";
import HelpCard from "./HelpCard";
import { useAllTenantsBalances } from "../../hooks/use-balances";
import Events from "../Event/Events";
import Tooltip from "../UI/Tooltip";
import useAccount from "../../hooks/use-account";
import Statistiques from "./Statistiques";
import Processes from "../Processes/Processes";
import useProcesses from "../../hooks/use-processes";
import { TypeAbonnement } from "../../utils/constants/account";

function Dashboard() {
  const {
    properties,
    isLoading: isLoadingProperties,
    error: errorProperties,
  } = useProperties();
  const [HelpModal, , openHelpModal] = useModal();
  const [LoyersModal, , openLoyersModal] = useModal();
  const [EdlModal, , openEdlModal] = useModal();
  const [ProcessModal, closeProcessModal, openProcessModal] = useModal();
  const [CourrierModal, , openCourrierModal] = useModal();
  const [TenantQuittanceModal, , openTenantQuittanceModal] = useModal();
  const { activeProcesses } = useProcesses();
  const {
    profileNoms,
    isLoading: isLoadingProfileNoms,
    error: errorProfileNoms,
  } = useProfileNoms();
  const nav = useNavigate();
  const {
    tenants,
    isLoading: isLoadingTenants,
    error: errorTenants,
  } = useTenants();
  const { balances } = useAllTenantsBalances(false);
  const { account } = useAccount();

  const nbCandidats = tenants.filter(
    (tenant) => tenant.status === TenantStatus.Candidat.status,
  ).length;

  const nbPropertiesLouees = tenants.filter(
    (tenant) =>
      moment().isBetween(tenant.debut, tenant.fin) &&
      tenant.status === TenantStatus.Actif.status,
  ).length;

  let locatairesEnRetard = 0;
  let locatairesImpaye = 0;
  let locatairesDGRetard = 0;
  let numberOfWarning = 0;
  for (let tenant of tenants) {
    if (tenant.status !== TenantStatus.Actif.status) continue;
    const balance = balances?.find(
      (balance) => balance.id_tenant === tenant.id,
    );
    if (!balance) continue;

    if (-balance.balance > tenant.loyer_hc + tenant.charges) {
      locatairesImpaye++;
    } else if (balance.balance < 0) {
      locatairesEnRetard++;
    }
    if (balance.dg_credit + balance.dg_debit < 0) {
      locatairesDGRetard++;
    }
  }

  [locatairesImpaye, locatairesEnRetard, locatairesDGRetard].forEach(
    (indicateur) => {
      if (indicateur > 0) numberOfWarning++;
    },
  );
  // Gestion de la carte LOYER À JOUR
  let loyerCardText, loyerCardType, loyerCardNombre;

  if (numberOfWarning === 0) {
    loyerCardText = "Loyers à jour";
    loyerCardNombre = <Check width={24} height={24} color={"green"} />;
  } else if (numberOfWarning === 1) {
    if (locatairesEnRetard > 0) {
      loyerCardText = (
        <TextOneOrMany
          one={"Locataire en retard"}
          many={"Locataires en retard"}
          number={locatairesEnRetard}
        />
      );
      loyerCardType = "warning";
      loyerCardNombre = locatairesEnRetard;
    } else if (locatairesImpaye > 0) {
      loyerCardText = (
        <TextOneOrMany
          one={"Locataire en impayé"}
          many={"Locataires en impayé"}
          number={locatairesImpaye}
        />
      );
      loyerCardType = "error";
      loyerCardNombre = locatairesImpaye;
    } else if (locatairesDGRetard > 0) {
      loyerCardText = (
        <TextOneOrMany
          one={"DG impayé"}
          many={"DG impayés"}
          number={locatairesDGRetard}
        />
      );
      loyerCardType = "error";
      loyerCardNombre = locatairesDGRetard;
    }
  } else {
    loyerCardText = (
      <>
        {locatairesEnRetard > 0 && (
          <>
            <span className="warning">
              Retard : {locatairesEnRetard}{" "}
              <TextOneOrMany
                number={locatairesEnRetard}
                one={"locataire"}
                many={"locataires"}
              />
            </span>
            <br />
          </>
        )}
        {locatairesImpaye > 0 && (
          <>
            <span className="error">
              Impayé : {locatairesImpaye}{" "}
              <TextOneOrMany
                number={locatairesImpaye}
                one={"locataire"}
                many={"locataires"}
              />
            </span>
            <br />
          </>
        )}
        {locatairesDGRetard > 0 && (
          <>
            <span className="error">
              DG impayé : {locatairesDGRetard}{" "}
              <Tooltip content={"Nombre de dépôts de garantie en retard"}>
                <Interrogation />
              </Tooltip>
            </span>
          </>
        )}
      </>
    );
  }

  let steps = [];
  let currentStep = "";
  if (profileNoms?.length === 0 && !isLoadingProfileNoms && !errorProfileNoms) {
    currentStep = "profil-bailleur";
    steps = [
      {
        target: "[data-joyride='profil-bailleur']",
        content: (
          <>
            <p className={"primaryText"}>
              Bienvenue sur Qalimo, votre assistant de gestion locative.
            </p>
            <p className={"secondaryText marginT10"}>
              La première étape est de créer votre profil bailleur. Cela vous
              permettra ensuite de créer un bien, auquel vous rattacherez vos
              locataires.
            </p>
          </>
        ),
        disableBeacon: true,
      },
    ];
  } else if (
    properties?.length === 0 &&
    !isLoadingProperties &&
    !errorProperties
  ) {
    currentStep = "add-property";
    steps = [
      {
        target: "[data-joyride='add-property']",
        content: (
          <>
            <p className={"primaryText"}>
              Votre profil bailleur est bien créé, vous pouvez maintenant
              ajouter votre bien.
            </p>
            <p className={"secondaryText marginT10"}>
              Si vous souhaitez créer une colocation avec baux individuels, il
              faudra créer un "bien" par chambre.
            </p>
            <p className={"secondaryText marginT10"}>
              Si vous avez plusieurs biens dans le même immeuble, il est
              possible de dupliquer un bien. Dans la liste des biens, cliquez
              sur les "..." du bien que vous souhaitez dupliquer.
            </p>
          </>
        ),
        disableBeacon: true,
      },
    ];
  } else if (tenants?.length === 0 && !isLoadingTenants && !errorTenants) {
    currentStep = "add-tenants";
    steps = [
      {
        target: "[data-joyride='add-tenants']",
        content: (
          <>
            <p className={"primaryText"}>
              Votre bien a été créé. Vous pouvez maintenant y ajouter un
              locataire.
            </p>
            <div className={"secondaryText marginT10"}>
              Deux possibilités :
              <ul>
                <li>
                  <em>Ajouter un locataire déjà existant.</em>
                  <br />
                  S'il vous manque certaines informations obligatoires, vous
                  pouvez en mettre des aléatoires, il faudra cependant garder
                  cela à l'esprit lorsque vous générerez un document, qui pourra
                  réutiliser ces informations.
                </li>
                <li>
                  <em>Inviter un candidat.</em>
                  <br />
                  En fournissant le lien de votre bien au candidat, il pourra
                  compléter son dossier. Vous pourrez par la suite convertir ce
                  candidat en locataire, et générer son bail, en quelques
                  secondes seulement.
                </li>
              </ul>
            </div>
          </>
        ),
        disableBeacon: true,
      },
    ];
  }

  const body = (
    <>
      <JoyrideQalimo steps={steps} currentStep={currentStep} />
      <ProcessModal size={"big"} maxContentWidth={"lg"}>
        <Processes onFinish={closeProcessModal} />
      </ProcessModal>
      <CourrierModal>
        <SelectPropertyTenantAction
          tenants={tenants}
          properties={properties}
          filtering={"tenants"}
          onClick={(id) =>
            nav(`/bailleur/tenants/${id}?tab-tenant-show=actions`)
          }
          title={"Courriers - avenants - congés"}
          text={
            "Sélectionnez le locataire à qui vous souhaitez envoyer un courrier/avenant/congé"
          }
        />
      </CourrierModal>
      <EdlModal>
        <SelectPropertyTenantAction
          tenants={tenants}
          properties={properties}
          filtering={"tenants"}
          onClick={(id) => nav(`/bailleur/tenants/${id}?action=edl`)}
          title={"État des lieux"}
          text={
            "Sélectionnez le locataire pour lequel vous souhaitez faire un état des lieux."
          }
        />
      </EdlModal>
      <LoyersModal>
        <LoyersShow />
      </LoyersModal>
      <QuittanceForm ModalElement={TenantQuittanceModal} />
      <HelpModal>
        <HelpCard />
      </HelpModal>
      <div className={classnames(style.grid, "marginB10")}>
        <CardNumber
          logo={<HousePrivatif />}
          nombre={`${nbPropertiesLouees}/${
            properties.filter(
              (property) => property.status === PropertyStatus.Actif.status,
            ).length
          }`}
          text={nbPropertiesLouees > 1 ? "Logements loués" : "Logement loué"}
          onClick={() => nav("/bailleur/properties")}
        />
        <CardNumber
          logo={<Dossier />}
          nombre={nbCandidats}
          text={nbCandidats > 1 ? "Candidatures" : "Candidature"}
          onClick={() => nav("/bailleur/candidats")}
        />
        <CardNumber
          logo={<Process />}
          nombre={activeProcesses.length}
          text={"Processus en cours"}
          onClick={openProcessModal}
        />

        <CardNumber
          logo={<Argent />}
          nombre={loyerCardNombre}
          type={loyerCardType}
          onClick={() => openLoyersModal()}
          text={loyerCardText}
          className={"flex-grow"}
        />
      </div>
      <div className={style.body}>
        <Card className={style.raccourcis}>
          <IconAndText
            icon={<Raccourcis />}
            text={<h2 className={"margin0"}>Raccourcis</h2>}
          />
          <ButtonCard
            buttonStyle={"blue"}
            className={" marginT10 h-3em flex align-items-center"}
            display={"block"}
            onClick={() => nav("/bailleur/properties/add")}
          >
            <IconAndText icon={<HousePlus />} text={"Ajouter un bien"} />
          </ButtonCard>
          <span data-joyride="add-tenants">
            <ButtonCard
              buttonStyle={"blue"}
              padding={"xs"}
              display={"block"}
              className={"marginT5 h-3em flex align-items-center"}
              onClick={() => nav("/bailleur/tenants/add")}
            >
              <IconAndText icon={<People />} text={"Ajouter un locataire"} />
            </ButtonCard>
            <ButtonCard
              buttonStyle={"blue"}
              padding={"xs"}
              display={"block"}
              className={"marginT5 h-3em flex align-items-center"}
              onClick={() => nav("/bailleur/candidats/add")}
            >
              <IconAndText icon={<People />} text={"Inviter un candidat"} />
            </ButtonCard>
          </span>
          <ButtonCard
            buttonStyle={"blue"}
            className={"marginT5 h-3em flex align-items-center"}
            display={"block"}
            onClick={() => openTenantQuittanceModal()}
          >
            <IconAndText
              icon={<Quittance />}
              text={"Générer une quittance"}
              stroke={"#000000"}
            />
          </ButtonCard>
          <ButtonCard
            buttonStyle={"blue"}
            display={"block"}
            className={"marginT5 h-3em flex align-items-center"}
            onClick={() => openEdlModal()}
          >
            <IconAndText
              icon={<People />}
              text={"Réaliser un état des lieux"}
            />
          </ButtonCard>
          <ButtonCard
            className={"marginT5 h-3em flex align-items-center"}
            display={"block"}
            buttonStyle={"blue"}
            onClick={() =>
              window.open("https://www.qalimo.fr/bibliotheque/", "_blank")
            }
          >
            <IconAndText icon={<Documents />} text={"Modèles de document"} />
          </ButtonCard>
          <ButtonCard
            className={"marginT5 h-3em flex align-items-center "}
            display={"block"}
            buttonStyle={"blue"}
            onClick={() => openCourrierModal()}
          >
            <IconAndText icon={<Message />} text={"Envoyer un courrier"} />
          </ButtonCard>
        </Card>
        {tenants?.length === 0 && !isLoadingTenants && !errorTenants ? (
          <Card className={style.evenements}>
            <div className={"flex space-between wrap gap10"}>
              <IconAndText
                icon={<FirstSteps stroke={"#9D9D9D"} />}
                text={
                  <h2 className={"margin0"}>Vos premiers pas sur Qalimo</h2>
                }
              />
              <div className={"text-center"}>
                <Button
                  buttonStyle={"secondary"}
                  onClick={() => openHelpModal()}
                >
                  Nous contacter
                </Button>
              </div>
            </div>
            <p className={"marginT10"}>
              Etape 0 : regardez nos{" "}
              <Link to={"https://www.qalimo.fr/tutoriels/"} target={"_blank"}>
                tutoriels
              </Link>
            </p>
            <p className={"marginT10"}>
              Etape 1 :{" "}
              <Link to={"/bailleur/profiles/add"}>
                créez un ou plusieurs profil bailleur
              </Link>{" "}
              <span className="italique">
                (nom propre, indivision, SCI, etc.)
              </span>
            </p>
            <p className={"marginT10"}>
              Etape 2 :{" "}
              <Link to={"/bailleur/properties/add"}>créez un bien</Link>{" "}
              <span className="italique">
                (pour les colocations en baux individuels, il faut créer un bien
                par chambre)
              </span>
            </p>
            <p className={"marginT10"}>
              Etape 3 :{" "}
              <Link to={"/bailleur/tenants/add"}>
                rattachez un locataire ou invitez un candidat à postuler à la
                location
              </Link>
            </p>
            <p className={"marginT10"}>
              Etape 4 :{" "}
              <Link to={"/bailleur/finances/add"}>
                synchronisez votre compte bancaire
              </Link>{" "}
              <span className="italique">(facultatif)</span>
            </p>
            <p className={"marginT10"}>
              Etape 5 : Si besoin,{" "}
              <Link
                to={"https://calendly.com/app-qalimo/30min"}
                target={"_blank"}
              >
                réservez un créneau
              </Link>{" "}
              avec nos experts
            </p>
            <p className={"marginT10"}>
              Etape 6 : Vous n’avez plus qu’à découvrir nos différentes
              fonctionnalités !
            </p>
            <div className={"marginL20"}>
              <IconAndText
                icon={<ArrowDownRight />}
                text={
                  <p className={"marginT10"}>
                    Créez un contrat de location en quelques clics
                  </p>
                }
              />
              <IconAndText
                icon={<ArrowDownRight />}
                text={
                  <p className={"marginT10"}>
                    Etat des lieux numérique sur ordinateur ou mobile
                  </p>
                }
              />
              <IconAndText
                icon={<ArrowDownRight />}
                text={
                  <p className={"marginT10"}>Edition de quittances de loyers</p>
                }
              />
              <IconAndText
                icon={<ArrowDownRight />}
                text={
                  <p className={"marginT10"}>
                    Signature électronique des documents
                  </p>
                }
              />
              <IconAndText
                icon={<ArrowDownRight />}
                text={
                  <p className={"marginT10"}>
                    Courriers automatiques{" "}
                    <span className={"italique"}>
                      (avenants, courriers, congés, régularisation de charges,
                      etc.)
                    </span>
                  </p>
                }
              />
              <IconAndText
                icon={<ArrowDownRight />}
                text={
                  <p className={"marginT10"}>
                    Intégration automatique des paiements de loyer en
                    comptabilité
                  </p>
                }
              />
            </div>
          </Card>
        ) : (
          <Card className={style.evenements}>
            <Events />
          </Card>
        )}
      </div>
    </>
  );

  const header = (
    <div className={"paddingT10"}>
      <div className={"flex space-between align-items-center"}>
        <div className={"primaryText"}></div>
        <div
          className={"secondaryText marginT10 marginL20 marginR20 text-center"}
        >
          {account.type_abonnement === TypeAbonnement.GRATUIT ||
          moment(account.expiration_abonnement).isBefore(moment()) ? (
            <Card className={"padding10 marginB10"}>
              <p className={"primaryText"}>
                Vous utilisez Qalimo en version gratuite, limitée à un seul
                bien.
              </p>
              <p className={"secondaryText"}>
                <Link to={"/mon-compte"}>Prendre votre abonnement</Link> ou{" "}
                <Link to={"https://www.qalimo.fr/tarif/"} target={"_blank"}>
                  voir les tarifs
                </Link>
              </p>
            </Card>
          ) : (
            <h2>Bienvenue sur Qalimo !</h2>
          )}
          <div className={"marginT5 flex gap10 wrap justify-content-center"}>
            <Button
              onClick={() =>
                window.open(
                  "https://www.qalimo.fr/tutoriels/",
                  "_blank",
                  "noreferrer",
                )
              }
            >
              Tutoriels
            </Button>
            <Button buttonStyle={"secondary"} onClick={() => openHelpModal()}>
              Besoin d'aide <RoundedQuestion />
            </Button>
            <Button
              onClick={() =>
                window.open(
                  "https://www.facebook.com/groups/488560933232604",
                  "_blank",
                  "noreferrer",
                )
              }
            >
              Groupe Facebook
            </Button>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
  return (
    <Page
      header={header}
      body={
        <Tabs id="dash">
          <div className="container bg-blue paddingT10 border-blue">
            <div className="content">
              <Tabs.TabList>
                <Tabs.Tab panelId="vue-ensemble">Vue d'ensemble</Tabs.Tab>
                <Tabs.Tab panelId="statistiques">Statistiques</Tabs.Tab>
              </Tabs.TabList>
            </div>
          </div>
          <div className="blueSpace"></div>
          <div className="content">
            <Tabs.TabPanel id="vue-ensemble">{body}</Tabs.TabPanel>
            <Tabs.TabPanel id="statistiques">
              <Statistiques tenants={tenants} properties={properties} />
            </Tabs.TabPanel>
          </div>
        </Tabs>
      }
    />
  );
}

export default Dashboard;
