import Tooltip from "../UI/Tooltip";
import { Croix, Interrogation } from "../UI/Icons";
import Button from "../UI/Button";
import useLoader from "../../hooks/use-loader";
import Card from "../UI/Card";
import styles from "./PaymentMethod.module.css";
import IconAndText from "../UI/IconAndText";

function brandToLogo(brand) {
  switch (brand.toLowerCase()) {
    case "visa":
      return "./visa.svg";
    case "mastercard":
      return "./mastercard.svg";
    case "american express":
      return "./amex.svg";
    default:
      return "./card_logo.svg";
  }
}

export default function PaymentMethod({
  method,
  isDefault,
  onMarkDefault,
  onDelete,
  flat,
}) {
  const [markingDefault, markDefault] = useLoader(onMarkDefault);
  const [deleting, deleteMethod] = useLoader(onDelete);

  return (
    <Card className={styles.relative} noCard={flat}>
      <IconAndText
        icon={
          <img
            alt={"abonnements"}
            src={brandToLogo(method.card.brand)}
            height={50}
          />
        }
        text={
          <>
            **** **** **** {method.card.last4}
            <br />
            Expire le {method.card.exp_month}/{method.card.exp_year}
          </>
        }
      />
      {isDefault && (
        <>
          (par défaut{" "}
          <Tooltip
            content={
              <>
                Utilisée pour les paiements récurrents de l'abonnement. Les
                paiements seront facturés à l'adresse de facturation ci-dessous.
              </>
            }
          >
            <Interrogation />
          </Tooltip>
          )
        </>
      )}
      {!isDefault && !!onMarkDefault && (
        <Button
          buttonStyle={"link"}
          className={"marginL10"}
          isLoading={markingDefault}
          onClick={async () => {
            try {
              await markDefault(method.id);
            } catch (error) {
              console.error(error);
            }
          }}
        >
          Utiliser par défaut
        </Button>
      )}
      {!!onDelete && (
        <Button
          buttonStyle={"link"}
          className={styles.delete}
          isLoading={deleting}
          onClick={async () => {
            try {
              await deleteMethod(method.id);
            } catch (error) {
              console.error(error);
            }
          }}
        >
          <Croix />
        </Button>
      )}
    </Card>
  );
}
