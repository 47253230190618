import { useState } from "react";
import TextInput from "./TextInput";
import { useFormikContext } from "formik";
import Button from "./Button";
import useDebouncedValue from "../../hooks/use-debounced-value";
import { ReactSelectStandalone } from "./SelectStandalone";
import useAddressSearch from "../../hooks/use-address-search";

function buildAdresse(suggestion) {
  return `${suggestion.rue}, ${suggestion.ville} (${suggestion.cp})`;
}

export default function AddressFields({
  namePrefix = "",
  nameRue: nameRueProps = "adresse",
  dependOn,
  mandatory,
}) {
  const { setFieldValue, values, errors, touched, setFieldTouched } =
    useFormikContext();
  const [query, setQuery] = useState("");
  const [showIndividualFields, setShowIndividualFields] = useState(false);
  const debouncedQuery = useDebouncedValue(query, 250);
  const { suggestions, isLoading: isLoadingSuggestions } =
    useAddressSearch(debouncedQuery);

  const nameRue = `${namePrefix}${nameRueProps}`;
  const nameVille = `${namePrefix}ville`;
  const nameCP = `${namePrefix}cp`;
  const nameComplement = `${namePrefix}complement_adresse`;

  const isAddressInvalid =
    (errors[nameRue] || errors[nameVille] || errors[nameCP]) &&
    (touched[nameRue] || touched[nameVille] || touched[nameCP]);

  // Handle selecting a suggestion
  async function suggestionSelectHandler(suggestion) {
    setQuery("");
    await Promise.all([
      setFieldValue(nameVille, suggestion?.ville ?? ""),
      setFieldValue(nameCP, suggestion?.cp ?? ""),
      setFieldValue(nameRue, suggestion?.rue ?? ""),
    ]);
    return blurSelectHandler();
  }

  function blurSelectHandler() {
    return Promise.all([
      setFieldTouched(nameVille),
      setFieldTouched(nameCP),
      setFieldTouched(nameRue),
    ]);
  }

  let selectSuggestions = [...suggestions];
  let selectValue = "";
  if (values[nameRue] && values[nameVille] && values[nameCP]) {
    selectValue = {
      ville: values[nameVille],
      cp: values[nameCP],
      rue: values[nameRue],
    };
  }

  return (
    <>
      <div hidden={showIndividualFields}>
        <ReactSelectStandalone
          components={{ DropdownIndicator: null }}
          options={selectSuggestions}
          filterOption={null}
          value={selectValue}
          isClearable
          onBlur={blurSelectHandler}
          getOptionLabel={buildAdresse}
          getOptionValue={buildAdresse}
          onInputChange={(value) => setQuery(value)}
          onChange={suggestionSelectHandler}
          loadingMessage={() => <>Chargement des suggestions</>}
          noOptionsMessage={({ inputValue }) =>
            inputValue.length > 3 ? <>Aucune suggestion trouvée</> : null
          }
          placeholder="13 rue de la colline, Nancy (54000)"
          isLoading={isLoadingSuggestions}
          isInvalid={isAddressInvalid}
          error={"Veuillez sélectionner une adresse valide"}
          label={"Adresse"}
          mandatory
        />
      </div>
      <p
        className="secondaryText marginB10 marginT5"
        hidden={showIndividualFields}
      >
        Votre adresse n'apparaît pas ?{" "}
        <Button
          type={"button"}
          buttonStyle={"link"}
          onClick={() => setShowIndividualFields(true)}
        >
          Entrer manuellement
        </Button>
      </p>
      <TextInput
        name={nameRue}
        label={"Adresse"}
        placeholder={"13 Rue de la colline"}
        dependon={dependOn}
        hidden={!showIndividualFields}
      />
      <TextInput
        name={nameComplement}
        label={"Complément d'adresse"}
        placeholder={"Batiment B"}
        dependon={dependOn}
      />
      <div className={"flex wrap gap10"}>
        <TextInput
          type="text"
          name={nameCP}
          label={"Code postal"}
          placeholder="27290"
          dependon={dependOn}
          mandatory={mandatory}
          className={"flex-grow"}
          hidden={!showIndividualFields}
        />
        <TextInput
          type="text"
          name={nameVille}
          label={"Ville"}
          placeholder="Thénouville"
          dependon={dependOn}
          mandatory={mandatory}
          className={"flex-grow"}
          hidden={!showIndividualFields}
        />
      </div>
    </>
  );
}
