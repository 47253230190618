import {
  BalanceDomain,
  BalanceTypeCategory,
} from "../../utils/constants/balances";
import { ReactSelect } from "../UI/Select";
import { useBalanceTypes } from "../../hooks/use-balances";
import { classnames } from "../../utils";
import { getIn, useFormikContext } from "formik";

export default function BalanceTypeSelect({
  prefix = "",
  className,
  mandatory,
}) {
  const { balanceTypesByCategory, isLoading } = useBalanceTypes();
  const { values } = useFormikContext();
  if (isLoading) return null;

  const name = `${prefix}type`;
  const domaineBalance = getIn(values, `${prefix}domaine`);

  let options = [];
  if (domaineBalance === BalanceDomain.TENANT) {
    options = [
      ...balanceTypesByCategory[BalanceTypeCategory.LOCATAIRE_APPEL],
      ...balanceTypesByCategory[BalanceTypeCategory.LOCATAIRE_PAIEMENT],
      ...balanceTypesByCategory[BalanceTypeCategory.DG_CREDIT],
      ...balanceTypesByCategory[BalanceTypeCategory.DG_DEBIT],
      ...balanceTypesByCategory[BalanceTypeCategory.LOCATAIRE_REMBOURSEMENT],
    ]
      .map((balanceType, i) => ({
        label: balanceType.text,
        value: balanceType.static_id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  } else if (
    domaineBalance === BalanceDomain.PROPERTY ||
    domaineBalance === BalanceDomain.FOLDER
  ) {
    options = [
      ...balanceTypesByCategory[BalanceTypeCategory.CHARGE],
      ...balanceTypesByCategory[BalanceTypeCategory.TRAVAUX],
    ]
      .map((balanceType, i) => ({
        label: balanceType.text,
        value: balanceType.static_id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }
  return (
    <ReactSelect
      label="Catégorie d'opération"
      placeholder="Appel loyer"
      name={name}
      className={classnames(className)}
      options={options}
      mandatory={mandatory}
    />
  );
}
