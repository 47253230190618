import { useState } from "react";
import { ArrowRight, SearchIcon } from "../UI/Icons";
import styles from "./BankList.module.css";
import { useModal } from "../../hooks/use-modal";
import useDebouncedValue from "../../hooks/use-debounced-value";
import useNordigen from "../../hooks/use-nordigen";
import useLoader from "../../hooks/use-loader";
import Button from "../UI/Button";

export default function BankAdd() {
  const { institutions, selectInstitution } = useNordigen();
  const [isSelecting, selectInstitutionLoader] = useLoader(selectInstitution);
  const [filter, setFilter] = useState("");
  const debouncedFilter = useDebouncedValue(filter, 200);

  const filteredInstitutions = institutions.filter((institution) =>
    institution.name.toLowerCase().includes(debouncedFilter),
  );

  const [Modal] = useModal(true);

  return (
    <Modal>
      <div className={styles.inputGroup}>
        <div className={styles.inputGroupText}>
          <SearchIcon />
        </div>
        <input
          type="text"
          name="filtre"
          className={styles.inputSearch}
          placeholder="Filtrer"
          onChange={(e) => setFilter(e.target.value.toLowerCase())}
        />
      </div>
      <br />
      {filteredInstitutions.length > 0 ? (
        filteredInstitutions.map((institution) => (
          <div key={institution.id}>
            <Button
              type="button"
              buttonStyle={"secondary"}
              isLoading={isSelecting}
              onClick={() => selectInstitutionLoader(institution.id)}
              className={styles.buttonBank}
            >
              <img
                src={institution.logo}
                width={20}
                height={20}
                alt={institution.name}
                className={styles.img}
              />
              {institution.name} <ArrowRight classname={styles.arrow} />
            </Button>
          </div>
        ))
      ) : (
        <button>Aucune banque trouvée</button>
      )}
    </Modal>
  );
}
