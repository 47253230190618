import useResources from "./use-resources";

const ROOT_PATH = "property-folders";

export default function usePropertyFolders(options = {}) {
  const { withById = false } = options;
  const {
    resources: propertyFolders,
    resourcesById: propertyFoldersById,
    error,
    isLoading,
    update,
    del,
    create,
  } = useResources(ROOT_PATH, {}, { withById });

  return {
    propertyFolders,
    propertyFoldersById,
    error,
    isLoading,
    createPropertyFolder: create,
    updatePropertyFolder: update,
    deletePropertyFolder: del,
  };
}
