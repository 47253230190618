import { Form, Formik } from "formik";
import Button from "../UI/Button";
import { toInitialValuesFromSchema } from "../../models/utils";
import TextInput from "../UI/TextInput";
import {
  FINANCE_DECOUPE_FORM_INITIAL_VALUE,
  financeDecoupeValidationSchema,
} from "../../models/financeDecoupe";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import { ReactSelect } from "../UI/Select";
import useProfileNoms from "../../hooks/use-profile-noms";
import TransactionSplitFieldArray from "../Transaction/TransactionSplitFieldArray";
import { handleAPIError } from "../../utils";
import { useState } from "react";
import Card from "../UI/Card";

export default function FinanceConfigDecoupeForm({
  config,
  onSubmit,
  onDelete,
}) {
  const { profileNoms } = useProfileNoms();
  const initialValues = toInitialValuesFromSchema(
    financeDecoupeValidationSchema,
    config,
    FINANCE_DECOUPE_FORM_INITIAL_VALUE,
  );
  const [error, setError] = useState("");

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { resetForm }) => {
        try {
          setError("");
          await onSubmit(values);
          resetForm();
        } catch (e) {
          setError(handleAPIError(e));
        }
      }}
      enableReinitialize={true}
      validationSchema={financeDecoupeValidationSchema}
    >
      <Form>
        <h2 className={"margin0"}>Template de découpe de transaction</h2>

        <TextInput
          type={"text"}
          label={"Nom du template"}
          name={"nom"}
          className={"marginT10"}
          mandatory
        />
        <ReactSelect
          name="id_profile_nom"
          options={profileNoms.map((option) => ({
            label: option.nom_profile,
            value: option.id,
          }))}
          placeholder={"SCI Dupont"}
          className={"marginB10"}
        />
        <TransactionSplitFieldArray name={"splits"} />
        {error && (
          <Card className={"marginT10"} type={"error"}>
            {error}
          </Card>
        )}
        <div className={"marginT10 text-right"}>
          {config && (
            <Button
              type="button"
              buttonStyle={"secondaryDelete"}
              className={"marginR10"}
              onClick={() => onDelete(config.id)}
            >
              Supprimer
            </Button>
          )}
          <FormikSubmitButton>Valider</FormikSubmitButton>
        </div>
      </Form>
    </Formik>
  );
}
