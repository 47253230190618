import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { number, object } from "yup";
import TextInput from "../UI/TextInput";
import styles from "./AuthForm.module.css";
import useAuth from "../../hooks/use-auth";
import { axiosPublic } from "../../api";
import { useState } from "react";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import Card from "../UI/Card";
import { handleAPIError } from "../../utils";
import ReactPixel from "react-facebook-pixel";

const INITIAL_VALUES = { hash: "" };
const VALIDATION_SCHEMA = object({
  hash: number()
    .integer()
    .positive()
    .required("Code invalide")
    .typeError("Code invalide"),
});

function AuthFormSignupVerify() {
  const { buildAndSetAuth } = useAuth();
  const nav = useNavigate();
  const location = useLocation();
  const from =
    (location.state?.from?.pathname ?? "/") +
    (location.state?.from?.search ?? "");
  const queryParams = new URLSearchParams(location.search);
  const mail = queryParams.get("mail");
  const [error, setError] = useState("");

  async function signMeUp(values) {
    try {
      setError("");
      const data = {
        hash: values.hash,
        mail,
      };
      const response = await axiosPublic.post("/signup/verify", data);
      buildAndSetAuth(response.data);
      ReactPixel.track("CompleteRegistration");
      nav(from);
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 400 || error.response.status === 404)
      ) {
        setError("Code invalide");
      } else {
        setError(handleAPIError(error));
      }
    }
  }

  return (
    <Formik
      className={styles.form}
      initialValues={INITIAL_VALUES}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={signMeUp}
    >
      {({ values }) => (
        <Form className={styles.form}>
          <TextInput
            type="text"
            name="hash"
            placeholder="Entrez le code recu par email"
          />
          <FormikSubmitButton disabled={!values.hash}>
            Valider
          </FormikSubmitButton>
          {error && (
            <Card type={"error"} className={"padding10 text-center marginT10"}>
              {error}
            </Card>
          )}
          <div className={styles.rememberMe}></div>
        </Form>
      )}
    </Formik>
  );
}

export default AuthFormSignupVerify;
