import { Form, Formik } from "formik";
import { sanitizeValues } from "../../models/utils";
import { edlRealisationValidationSchema } from "../../models/edl";
import EdlFirstPageForm from "./EdlFirstPageForm";
import EdlLastPageForm from "./EdlLastPageForm";
import useAuthenticatedAxios from "../../hooks/use-authenticated-axios";
import { useRef, useState } from "react";
import moment from "moment";
import TabbedForm from "../UI/TabbedForm";
import useProfileNoms from "../../hooks/use-profile-noms";
import { handleAPIError } from "../../utils";
import ChooseSendFileMethod from "../UI/ChooseSendFileMethod";
import { useNavigate } from "react-router-dom";
import { ProfileStatus } from "../../models/profile";
import EdlShow from "./EdlShow";
import { Maison } from "../UI/Icons";
import EdlIntroduction from "./EdlIntroduction";

function EdlRealisation({
  tenant,
  property,
  modal: ModalElement,
  onSubmitted,
}) {
  const axios = useAuthenticatedAxios();
  const [edlPreview, setEdlPreview] = useState();
  const formRef = useRef();
  const { profileNoms } = useProfileNoms();
  const [error, setError] = useState("");
  const nav = useNavigate();

  if (!property) return null;
  const profileNomBailleur = profileNoms?.find(
    (profile) => profile.id === property.id_profile_nom_owner,
  );

  async function submitHandler(values, preview = false) {
    const sanitizedValues = { ...sanitizeValues(values) };
    const typeEnvoi = sanitizedValues.type_envoi;
    delete sanitizedValues.type_envoi;

    let params = { type_envoi: typeEnvoi };
    if (preview === true) {
      params["preview"] = true;
    }
    try {
      const response = await axios.post(
        `/biens/${property.id}/generate-edl/${tenant.id}`,
        sanitizedValues,
        { params },
      );
      if (preview === true) {
        setEdlPreview(response.data);
      } else {
        onSubmitted();
        nav(
          `/bailleur/tenants/${tenant.id}?tab-tenant-show=documents-courriers`,
        );
      }
      setError("");
    } catch (error) {
      setError(handleAPIError(error));
    }
  }

  const initialValues = {
    profiles: tenant?.profile_nom.profiles
      .filter((p) => p.status === ProfileStatus.Actif.status)
      .map((p) => {
        return {
          present: "Présent",
          nom:
            p.type === 1
              ? `${p.prenom} ${p.nom}`
              : `${p.prenom} ${p.nom} gérant de la société ${p.nom_societe}`,
          represente_prenom: "",
          represente_nom: "",
          represente_qualite: "",
        };
      }),
    profiles_bailleurs: profileNomBailleur?.profiles.map((p) => {
      return {
        present: "Présent",
        nom:
          p.type === 1
            ? `${p.prenom} ${p.nom}`
            : `${p.prenom} ${p.nom} gérant de la société ${p.nom_societe}`,
        represente_prenom: "",
        represente_nom: "",
        represente_qualite: "",
      };
    }),
    type_edl: "Entrée",
    compteur_elec: property.compteur_elec ?? "",
    elec_heures_creuses: "",
    elec_heures_pleines: "",
    compteur_gaz: property.compteur_gaz ?? "",
    gaz: "",
    compteur_eau: property.compteur_eau ?? "",
    indice_dpe: "",
    eau_froide: "",
    eau_chaude: "",
    description: "",
    date: moment().format("YYYY-MM-DD"),
    cles: [],
    type_envoi: "elec",
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={submitHandler}
      validationSchema={edlRealisationValidationSchema}
    >
      {(formikProps) => {
        return (
          <TabbedForm id="do-edl-form" formikProps={formikProps} ref={formRef}>
            <ModalElement
              stickyHeader
              size={"ultra"}
              onClose={() => {
                formRef.current?.resetTab();
              }}
              header={
                <TabbedForm.TabList>
                  <TabbedForm.Tab panelId="introduction">
                    Introduction
                  </TabbedForm.Tab>
                  <TabbedForm.Tab panelId="identification">
                    Identification
                  </TabbedForm.Tab>
                  <TabbedForm.Tab panelId="piece">Pièces</TabbedForm.Tab>
                  <TabbedForm.Tab panelId="conclusion">
                    Conclusion
                  </TabbedForm.Tab>
                  <TabbedForm.Tab panelId="document">
                    Validation & envoi
                  </TabbedForm.Tab>
                </TabbedForm.TabList>
              }
            >
              <Form>
                <div className="marginT10">
                  <TabbedForm.TabPanel id="introduction">
                    <EdlIntroduction />
                  </TabbedForm.TabPanel>
                  <TabbedForm.TabPanel id="identification">
                    <EdlFirstPageForm
                      tenant={tenant}
                      values={formikProps.values}
                      profileNomBailleur={profileNomBailleur}
                    />
                  </TabbedForm.TabPanel>
                  <TabbedForm.TabPanel id="piece">
                    <div
                      className={
                        "flex align-items-center justify-content-center marginT20 marginB20"
                      }
                    >
                      <Maison />
                      <h2 className={"marginL10"}>Pièces</h2>
                    </div>
                    <EdlShow
                      propertyId={property.id}
                      isRealisation
                      property={property}
                    />
                    <TabbedForm.Actions error={error} />
                  </TabbedForm.TabPanel>
                  <TabbedForm.TabPanel id="conclusion">
                    <EdlLastPageForm
                      onSubmit={submitHandler}
                      edlPreview={edlPreview}
                    />
                    <TabbedForm.Actions error={error} />
                  </TabbedForm.TabPanel>
                  <TabbedForm.TabPanel id="document">
                    <ChooseSendFileMethod
                      values={formikProps.values}
                      propertyId={tenant?.id_bien}
                      profiles={tenant?.profile_nom.profiles}
                      error={error}
                    />
                  </TabbedForm.TabPanel>
                </div>
              </Form>
            </ModalElement>
          </TabbedForm>
        );
      }}
    </Formik>
  );
}

export default EdlRealisation;
