import Button from "../UI/Button";
import Card from "../UI/Card";
import { useModal } from "../../hooks/use-modal";
import AddressForm from "./AddressForm";
import useAccount from "../../hooks/use-account";

export default function AddressFacturation() {
  const [AddressModal, closeAddressModal, openAddressModal] = useModal(false);
  const { account, hasValidAddress, updateCompte } = useAccount();

  return (
    <>
      <AddressModal size="small">
        <AddressForm
          account={account}
          onSubmit={async (values) => {
            await updateCompte(values);
            closeAddressModal();
          }}
        />
      </AddressModal>
      <Card className={"marginT10"}>
        <div className={"flex wrap gap10 space-between"}>
          <p className={"cardTitle"}>Adresse de facturation</p>
          <Button
            type="button"
            buttonStyle={"secondary"}
            onClick={openAddressModal}
          >
            {hasValidAddress
              ? "Modifier"
              : "Ajouter une adresse de facturation"}
          </Button>
        </div>
        <p>{account.nom}</p>
        <p>{account.adresse}</p>
        <p>{account.complement_adresse}</p>
        <p>
          {account.cp} {account.ville}
        </p>
      </Card>
    </>
  );
}
