import { Form, Formik } from "formik";
import TextInput from "../UI/TextInput";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import Select from "../UI/Select";
import Radio from "../UI/Radio";
import RadioGroup from "../UI/RadioGroup";
import { courrierValidationSchema } from "../../models/courrier";
import Editor from "../Quill/Editor";
import TextArea from "../UI/TextArea";

function CourrierForm({ initialValues, onSubmit }) {
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={courrierValidationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => {
          function setValueHandler(value) {
            setFieldValue("content", value);
          }

          return (
            <Form>
              <RadioGroup>
                <Radio name="status" value="1" label="Actif" />
                <Radio name="status" value="0" label="Brouillon" />
              </RadioGroup>
              <TextInput type="text" name="nom" label={"Nom"} mandatory />
              <Select name={"type"}>
                <option value={"courrier"}>Courrier</option>
                <option value={"avenant"}>Avenant</option>
                <option value={"conge"}>Congé</option>
                <option value={"caution"}>Caution</option>
              </Select>
              <Select name={"category"}>
                <option value={""}>Vide</option>
                <option value={"impaye"}>Impayé</option>
                <option value={"trouble"}>Trouble</option>
                <option value={"justificatif"}>Justificatif</option>
                <option value={"information"}>Information</option>
                <option value={"meuble"}>Meublé</option>
                <option value={"nu"}>Nu</option>
                <option value={"montant"}>Montant</option>
              </Select>
              <TextArea type="text" name="text" label="Texte de la carte" />
              <Editor
                value={values.content}
                onChange={setValueHandler}
                noSubmit
              />
              <div className={"text-right"}>
                <FormikSubmitButton>Valider</FormikSubmitButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default CourrierForm;
