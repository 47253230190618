import useTenants from "../../hooks/use-tenants";
import Card from "../UI/Card";
import { Table } from "../UI/Table";
import { TenantStatus } from "../../models/tenant";
import moment from "moment";
import InfoBoxList from "../UI/InfoBoxList";
import { Link } from "react-router-dom";
import { usePublicProperties } from "../../hooks/use-properties";

function LocataireCandidaturesList() {
  const { tenants } = useTenants({ isLocataire: true });
  const { publicProperties } = usePublicProperties();
  return (
    <>
      <div className="container marginT-20">
        <Card className="content">
          <Table>
            <Table.Header>
              <Table.Column>Date de candidature</Table.Column>
              <Table.Column>Bien</Table.Column>
              <Table.Column>Status</Table.Column>
              <Table.Column>Action</Table.Column>
            </Table.Header>
            <Table.Body className={"secondaryText"}>
              {tenants.length < 1 ? (
                <Table.Row>
                  <Table.Cell colSpan="100%">
                    <div className={"text-center secondaryText marginT20"}>
                      Aucune candidature trouvée
                    </div>
                  </Table.Cell>
                </Table.Row>
              ) : (
                tenants.map((tenant) => (
                  <Table.Row key={tenant.id}>
                    <Table.Cell>
                      {moment(tenant.creation_date).format("DD MMMM YYYY")}
                    </Table.Cell>
                    <Table.Cell>
                      <Link
                        className={"smallText"}
                        to={`/candidat?bien=${
                          publicProperties?.find(
                            (property) => tenant.id_bien === property.id,
                          )?.id
                        }`}
                      >
                        {
                          publicProperties?.find(
                            (property) => tenant.id_bien === property.id,
                          )?.nom
                        }
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {tenant.status === TenantStatus.Candidat.status && (
                        <InfoBoxList
                          text={"En cours d'étude par le bailleur"}
                          color={"yellow"}
                        />
                      )}
                      {tenant.status ===
                        TenantStatus.CandidatArchive.status && (
                        <InfoBoxList
                          text={"Refusé par le bailleur"}
                          color={"red"}
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell>Aucune action disponible</Table.Cell>
                  </Table.Row>
                ))
              )}
            </Table.Body>
          </Table>
        </Card>
      </div>
    </>
  );
}

export default LocataireCandidaturesList;
