import moment from "moment";
import useSuiviRecommande from "../../hooks/use-suivi-recommande";
import { Link } from "react-router-dom";
import Spinner from "../UI/Spinner";

export default function SuiviRecommande({ file }) {
  const { suiviRecommande, error, isLoading } = useSuiviRecommande(file?.id);
  if (!file) {
    return null;
  }

  const fallback = (
    <div>
      Vous pouvez suivre votre colis{" "}
      <Link
        to={`https://www.laposte.fr/outils/suivre-vos-envois?code=${file.id_laposte}`}
        target={"_blank"}
      >
        ici
      </Link>
    </div>
  );

  if (error) return fallback;

  if (isLoading) return <Spinner width={"3em"} />;

  const suiviEvents = suiviRecommande.event;

  if (!suiviEvents && !suiviRecommande.returnMessage) return fallback;

  return (
    <div>
      {file.laposte_delivered_at ? (
        <h2>
          Votre lettre a été livrée le{" "}
          {moment(file.laposte_delivered_at).format("DD/MM/YYYY")}
        </h2>
      ) : (
        <h2>Votre lettre est en cours d'envoi...</h2>
      )}
      <div>
        {suiviEvents &&
          suiviEvents.map((ev) => (
            <p>
              <span className={"primaryText"}>
                {moment(ev.date).format("DD/MM/YYYY-HH:mm")} :
              </span>{" "}
              {ev.label}
            </p>
          ))}
        {!suiviEvents && <p>{suiviRecommande.returnMessage}</p>}
      </div>
    </div>
  );
}
