import { useEffect, useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import Button from "../UI/Button";
import Card from "../UI/Card";
import { useModal } from "../../hooks/use-modal";
import style from "./CheckoutForm.module.css";
import useStripePaymentMethods from "../../hooks/use-stripe-payment-methods";
import PaymentMethod from "./PaymentMethod";
import RadioGroup from "../UI/RadioGroup";
import RadioStandalone from "../UI/RadioStandalone";
import useAccount from "../../hooks/use-account";
import AddressFacturation from "../Account/AddressFacturation";
import CGVCheckbox from "./CGVCheckbox";
import ReactPixel from "react-facebook-pixel";

export default function CheckoutForm({
  intentType,
  amount,
  clientSecret,
  intentStatus,
  setIntentStatus,
  finishPayment,
  onIntentSuccessful,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState("blue");
  const [isLoading, setIsLoading] = useState(false);
  const [iframeSrc, setIFrameSrc] = useState("about:blank");
  const [cgv, setCgv] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [IFrameModal, closeIFrameModal, openIFrameModal] = useModal(false);
  const {
    paymentMethods,
    isLoading: isLoadingPaymentMethods,
    defaultPaymentMethod,
  } = useStripePaymentMethods();
  const { hasValidAddress } = useAccount();

  useEffect(() => {
    if (paymentMethods.length > 0 && intentType === "payment") {
      if (defaultPaymentMethod) {
        setPaymentMethod(defaultPaymentMethod);
      } else {
        setPaymentMethod(paymentMethods[0].id);
      }
    }
  }, [defaultPaymentMethod, intentType, paymentMethods]);

  function intentCallback(intent, error) {
    if (error) {
      setMessage(error.message);
      setMessageType("error");
      return;
    }
    setIntentStatus(intent.status);
    switch (intent.status) {
      case "succeeded":
        setMessage(
          intentType === "setup"
            ? "Carte enregistrée avec succès."
            : "Paiement réussi.",
        );
        setMessageType("green");
        if (onIntentSuccessful) {
          onIntentSuccessful();
        }
        break;
      case "requires_payment_method":
        setMessage("Carte bancaire refusée, veuillez réessayer.");
        setMessageType("error");
        break;
      case "payment_failed":
        setMessage("Paiement refusé.");
        setMessageType("error");
        break;
      default:
        setMessage("Une erreur est survenue.");
        setMessageType("error");
        break;
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (!cgv) {
      setMessage("Veuillez accepter les conditions générales de vente.");
      setMessageType("error");
      return;
    }
    if (!hasValidAddress) {
      setMessage("Veuillez renseigner une adresse de facturation valide.");
      setMessageType("error");
      return;
    }

    setIsLoading(true);
    let data;
    if (intentType === "payment") {
      data = {
        value: amount,
        currency: "EUR",
      };
      ReactPixel.track("InitiateCheckout", data);
    } else if (intentType === "setup") {
      ReactPixel.track("AddPaymentInfo");
    }

    let finalPaymentMethod = paymentMethod;
    if (!finalPaymentMethod) {
      const submit = await elements.submit();
      if (submit.error) {
        setMessage(submit.error.message);
        setMessageType("error");
        setIsLoading(false);
        return;
      }
      const createPaymentMethod = await stripe.createPaymentMethod({
        elements,
      });
      if (createPaymentMethod.error) {
        setMessage(createPaymentMethod.error.message);
        setMessageType("error");
        setIsLoading(false);
        return;
      }
      finalPaymentMethod = createPaymentMethod.paymentMethod.id;
    }
    const stripeConfirm =
      intentType === "setup"
        ? stripe.confirmCardSetup
        : stripe.confirmCardPayment;
    const confirmIntent = await stripeConfirm(
      clientSecret,
      {
        payment_method: finalPaymentMethod,
        return_url: `${process.env.REACT_APP_FRONTEND_URL}/bailleur/stripe/return`,
      },
      {
        handleActions: false,
      },
    );
    const intent =
      intentType === "setup"
        ? confirmIntent.setupIntent
        : confirmIntent.paymentIntent;
    if (confirmIntent.error) {
      setMessage(confirmIntent.error.message);
      setMessageType("error");
      setIsLoading(false);
      return;
    }

    if (intent.status === "requires_action") {
      openIFrameModal();
      setIFrameSrc(intent.next_action.redirect_to_url.url);
      window.stripeCallback = (intent, error) => {
        closeIFrameModal();
        intentCallback(intent, error);
      };
    } else {
      intentCallback(intent, confirmIntent.error);
    }
    setIsLoading(false);
    if (intentType === "payment") {
      ReactPixel.track("Purchase", data);
    }
  }

  return (
    <>
      <IFrameModal noPadding>
        <iframe
          title="3D Secure authentification"
          src={iframeSrc}
          width={400}
          height={600}
          className={style.iframe}
        />
      </IFrameModal>
      <form onSubmit={handleSubmit}>
        {intentType === "payment" && (
          <RadioGroup
            styling={"button"}
            layout={"column"}
            className={style.paymentMethod}
          >
            {paymentMethods.map((method, i) => (
              <RadioStandalone
                name="payment_method"
                checked={method.id === paymentMethod}
                onChange={() => setPaymentMethod(method.id)}
                key={i}
                label={<PaymentMethod method={method} flat />}
              />
            ))}
            <RadioStandalone
              name="payment_method"
              label="Nouvelle carte"
              checked={!paymentMethod}
              onChange={() => setPaymentMethod("")}
            />
          </RadioGroup>
        )}
        <div
          hidden={
            intentType === "payment" &&
            (isLoadingPaymentMethods ||
              (paymentMethods.length > 0 && !!paymentMethod))
          }
          className="marginT10"
        >
          <PaymentElement />
        </div>
        <AddressFacturation />
        <CGVCheckbox
          checked={cgv}
          onChange={() => setCgv((prevState) => !prevState)}
        />
        {message && (
          <Card type={messageType} className={"padding10 marginT10"}>
            {message}
          </Card>
        )}
        <div className={"text-right"}>
          {intentStatus === "succeeded" ? (
            <Button
              type="button"
              onClick={finishPayment}
              className={"marginT10"}
            >
              Fermer
            </Button>
          ) : (
            <Button
              isLoading={isLoading}
              disabled={isLoading || !stripe || !elements}
              className={"marginT10"}
            >
              {intentType === "setup" ? "Enregistrer" : "Payer"}
            </Button>
          )}
        </div>
      </form>
    </>
  );
}
