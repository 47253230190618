import { useEffect, useRef, useState } from "react";

export default function DocumentDownloader({ children, className }) {
  const [signedUrl, setSignedUrl] = useState("");
  const linkRef = useRef();

  // eslint-disable-next-line jsx-a11y/anchor-has-content
  const link = <a href={signedUrl} ref={linkRef}></a>;

  useEffect(() => {
    if (signedUrl) {
      linkRef.current.click();
      setSignedUrl("");
    }
    return () => {
      if (signedUrl) {
        // Revoke file URL if it was a local file
        URL.revokeObjectURL(signedUrl);
      }
    };
  }, [signedUrl]);

  return (
    <div className={className}>
      {signedUrl && link}
      {children && children(setSignedUrl)}
    </div>
  );
}
