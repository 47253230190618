import TextInput from "../../UI/TextInput";
import IconAndText from "../../UI/IconAndText";
import { Attention, Check, Croix, Interrogation } from "../../UI/Icons";
import { Fragment, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Checkbox from "../../UI/Checkbox";
import { Form, Formik } from "formik";
import Radio from "../../UI/Radio";
import { CAUTION_INITIAL_VALUE, cautionSchema } from "../../../models/courrier";
import TabbedForm from "../../UI/TabbedForm";
import ChooseSendFileMethod from "../../UI/ChooseSendFileMethod";
import { ProfileStatus } from "../../../models/profile";
import Button from "../../UI/Button";
import { sanitizeValues } from "../../../models/utils";
import { handleAPIError, showFormikFieldsInError } from "../../../utils";
import useAuthenticatedAxios from "../../../hooks/use-authenticated-axios";
import Tooltip from "../../UI/Tooltip";
import FormUpload from "../../File/FormUpload";
import useFiles from "../../../hooks/use-files";
import Card from "../../UI/Card";
import DocumentDownloadLink from "../../UI/DocumentDownloadLink";
import DocumentThumbnail from "../../UI/DocumentThumbnail";
import { DocType } from "../../../utils/constants/documents";

export default function CautionForm({ tenant, profiles, Modal }) {
  const formRef = useRef();
  const [error, setError] = useState();
  const [listPreviewDocuments, setListPreviewDocuments] = useState();
  const axios = useAuthenticatedAxios();
  const nav = useNavigate();
  const { files } = useFiles({
    tenantId: tenant?.id,
  });
  const leaseFound = files.filter((file) => file.tag === "bail");
  const filteredGarantors = profiles?.filter(
    (filtered) =>
      filtered.garantor_of === null &&
      filtered.status === ProfileStatus.Actif.status &&
      filtered.garantors.length > 0,
  );
  async function submitHandler(values, { resetForm }, preview = false) {
    const sanitizedValues = { ...sanitizeValues(values) };
    sanitizedValues.courrier_content = null;
    sanitizedValues.preview = preview;
    try {
      const response = await axios.post(
        `/tenant/${tenant.id}/caution`,
        sanitizedValues,
      );
      if (preview === true) {
        setListPreviewDocuments(response.data);
      } else {
        nav(
          `/bailleur/tenants/${tenant.id}?tab-tenant-show=documents-courriers`,
        );
        resetForm();
      }
      setError("");
    } catch (error) {
      setError(handleAPIError(error));
    }
  }

  const filteredProfiles = profiles?.filter(
    (filtered) =>
      filtered.garantor_of === null &&
      filtered.status === ProfileStatus.Actif.status,
  );

  const initialValues = {
    ...CAUTION_INITIAL_VALUE,
    locataires: filteredProfiles.map((profile) => profile.id.toString()),
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={submitHandler}
      validationSchema={cautionSchema}
    >
      {(formikProps) => {
        let tabIndex = 0;
        return (
          <>
            <TabbedForm
              draftType={2}
              formikProps={formikProps}
              id="avenant-form"
              ref={formRef}
            >
              <Modal
                stickyHeader
                blueHeader
                size={"big"}
                onCancel={() => {
                  setError("");
                  formRef.current?.resetTab();
                }}
                header={
                  <>
                    <img
                      src={`/photo_caution.png`}
                      alt="Caution"
                      className={"center marginB10"}
                    />
                    <TabbedForm.TabList>
                      <TabbedForm.Tab
                        panelId="avertissement"
                        index={tabIndex++}
                      >
                        Avertissement
                      </TabbedForm.Tab>

                      {filteredGarantors.length > 0 && (
                        <>
                          <TabbedForm.Tab panelId="form" index={tabIndex++}>
                            Paramètres
                          </TabbedForm.Tab>
                          <TabbedForm.Tab panelId="document" index={tabIndex++}>
                            Validation & envoi
                          </TabbedForm.Tab>
                        </>
                      )}
                    </TabbedForm.TabList>
                  </>
                }
              >
                <Form>
                  <div className="marginT10">
                    <TabbedForm.TabPanel id="avertissement">
                      <div className={"flex justify-content-center"}>
                        <div className={"marginT20 marginB20"}>
                          {filteredGarantors.length > 0 ? (
                            <>
                              <IconAndText
                                icon={<Attention />}
                                text={
                                  <p className={"primaryText"}>
                                    Vous ne pouvez pas demander de cautionnement
                                    :
                                  </p>
                                }
                                className={"marginB20"}
                              />
                              <IconAndText
                                icon={<Croix color={"red"} size={"15"} />}
                                text="Si vous avez déjà une garantie/assurance loyers impayées (GLI)"
                                className={"marginB5"}
                              />
                              <IconAndText
                                icon={<Croix color={"red"} size={"15"} />}
                                text="Si vous avez déjà une garantie Visale"
                                className={"marginB5"}
                              />
                              <IconAndText
                                icon={<Croix color={"red"} size={"15"} />}
                                text="Si vous êtes une personne morale autre qu'une SCI de famille"
                                className={"marginB5"}
                              />
                              <IconAndText
                                icon={
                                  <Check width="15" height="15" color="green" />
                                }
                                text="Sauf si votre locataire est un étudiant ou un apprenti."
                                className={"marginB10"}
                              />
                            </>
                          ) : (
                            <Card type={"error"} className={"padding10 w-100"}>
                              <p>
                                Aucun de vos locataires n'a de garant. Vous
                                devez d'abord en ajouter un avant de pouvoir
                                faire un acte de cautionnement
                              </p>
                              {filteredProfiles.map((profile, index) => (
                                <div className={"marginT10"} key={index}>
                                  <Link
                                    to={`/bailleur/tenants/${tenant.id}/${profile.id}/add_garant?tab-tenant-show=vue-ensemble`}
                                  >
                                    + Ajouter un garant
                                  </Link>{" "}
                                  pour {profile.prenom} {profile.nom}
                                </div>
                              ))}
                            </Card>
                          )}
                        </div>
                      </div>
                      <TabbedForm.Actions error={error} />
                    </TabbedForm.TabPanel>
                    <TabbedForm.TabPanel id="form">
                      <p className={"marginB5 marginT20"}>
                        Sélectionner le type d'acte de cautionnement:
                      </p>
                      <div className={"gridColWrap240 marginB20"}>
                        <Radio
                          name="courrier_id"
                          value="caution_indeterminee"
                          label={
                            <>
                              Indéterminée{" "}
                              <Tooltip
                                content={
                                  "Lorsque le cautionnement est indéterminé, la caution peut le résilier unilatéralement pour la date de fin du contrat de location en cours, qu’il s’agisse du contrat initial ou d’un renouvellement."
                                }
                              >
                                <Interrogation />
                              </Tooltip>
                            </>
                          }
                        />
                        <Radio
                          name="courrier_id"
                          value="caution_determinee"
                          label={
                            <>
                              Déterminée{" "}
                              <Tooltip
                                content={
                                  "Le cautionnement prendra fin à la date de la durée prévue au contrat mais la caution ne pourra pas le résilier unilatéralement avant sa date d'échéance."
                                }
                              >
                                <Interrogation />
                              </Tooltip>
                            </>
                          }
                        />
                      </div>
                      {formikProps.values.courrier_id ===
                        "caution_determinee" && (
                        <TextInput
                          name={"duree"}
                          type={"number"}
                          unit={"ans"}
                          placeholder={"6"}
                          label={"Durée de l'acte de cautionnement en année "}
                          mandatory
                        />
                      )}
                      <p>
                        Sélectionner les locataires pour lesquels vous souhaitez
                        générer un acte de cautionnement :
                      </p>
                      <p className={"secondaryText marginB10 "}>
                        Si les informations garant sont érronées, rendez-vous
                        dans la section "
                        <Link to={`/bailleur/tenants/${tenant?.id}`}>
                          profil
                        </Link>
                        " de votre locataire pour les modifier.
                      </p>
                      {profiles
                        ?.filter(
                          (filtered) =>
                            filtered.garantor_of === null &&
                            filtered.status === ProfileStatus.Actif.status,
                        )
                        .map((profile, index) => (
                          <Checkbox
                            name={"locataires"}
                            value={profile.id.toString()}
                            key={index}
                          >
                            {profile.prenom} {profile.nom}, dont les garants
                            sont :{" "}
                            {profile.garantors.length > 0 ? (
                              profile.garantors.map((garantor, i) => (
                                <Fragment key={i}>
                                  {garantor.prenom} {garantor.nom}
                                </Fragment>
                              ))
                            ) : (
                              <>
                                <span className={"primaryText"}>
                                  Aucun garant trouvé.{" "}
                                </span>

                                <Link
                                  to={`/bailleur/tenants/${tenant.id}/${profile.id}/add_garant?tab-tenant-show=vue-ensemble`}
                                >
                                  + Ajouter ici
                                </Link>
                              </>
                            )}
                          </Checkbox>
                        ))}
                      <TextInput
                        name={"montant_max"}
                        type={"number"}
                        unit={"€"}
                        placeholder={"21600"}
                        label={"Montant maximum garanti"}
                        mandatory
                        className={"marginT20"}
                      />
                      <p className={"secondaryText marginB10"}>
                        L’acte de cautionnement doit mentionner un montant
                        maximum pour lequel la caution s’engage. Par défaut, il
                        est prévu l’équivalent de 3 ans de loyers et charges
                        correspondant à la durée maximum en cas de procédure
                        judiciaire pour expulser le locataire. Vous pouvez, si
                        vous le souhaitez, modifier à la hausse ou à la baisse
                        le montant fixé.
                      </p>
                      <p className={"primaryText marginT10 marginB10"}>
                        Il est obligatoire pour le bailleur de joindre une copie
                        du contrat de location, à peine de nullité de l’acte de
                        cautionnement.
                      </p>
                      {leaseFound.length > 0 ? (
                        <>
                          <p className={"marginB5"}>
                            Nous avons trouvé les documents suivants. Veuillez
                            vous assurer qu'ils sont corrects et signés :{" "}
                          </p>
                          <div className={"flex wrap gap10"}>
                            {leaseFound.map((file, i) => (
                              <DocumentDownloadLink
                                documentId={file.id}
                                doctype={DocType.GENERIC_FILE}
                                file={file}
                                key={i}
                              >
                                <DocumentThumbnail
                                  documentId={file.id}
                                  doctype={DocType.GENERIC_FILE}
                                  file={file}
                                />
                              </DocumentDownloadLink>
                            ))}
                          </div>
                        </>
                      ) : (
                        <Card type={"error"} className={"padding5"}>
                          Aucun bail trouvé
                        </Card>
                      )}

                      <p className={"marginT20"}>
                        Si vous souhaitez utiliser d'autres documents, ajoutez
                        les ici (les documents ci-dessus ne seront pas utilisés)
                      </p>
                      <FormUpload tag="bail" />
                      <Button
                        type={"button"}
                        onClick={async () => {
                          const errors = await formikProps.validateForm();
                          if (Object.keys(errors).length > 0) {
                            showFormikFieldsInError(
                              errors,
                              formikProps.setFieldTouched,
                            );
                            return;
                          }
                          return submitHandler(
                            formikProps.values,
                            formikProps,
                            true,
                          );
                        }}
                        disabled={!formikProps.isValid}
                        className={"marginT10"}
                      >
                        Prévisualiser le document
                      </Button>
                      <TabbedForm.Actions error={error} />
                      <div
                        className={"marginT10"}
                        dangerouslySetInnerHTML={{
                          __html: listPreviewDocuments,
                        }}
                      />
                    </TabbedForm.TabPanel>
                    <TabbedForm.TabPanel id="document">
                      <ChooseSendFileMethod
                        values={formikProps.values}
                        propertyId={tenant?.id_bien}
                        profiles={profiles
                          ?.filter((profile) =>
                            formikProps.values.locataires.includes(
                              profile.id.toString(),
                            ),
                          )
                          .flatMap((profile) => profile.garantors)}
                        caution
                      />
                    </TabbedForm.TabPanel>
                  </div>
                </Form>
              </Modal>
            </TabbedForm>
          </>
        );
      }}
    </Formik>
  );
}
