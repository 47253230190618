import { array, boolean, number, object, string } from "yup";
import { dateMax, stringMax } from "./utils";

export class ProfileStatus {
  static Actif = new ProfileStatus(1);
  static Archive = new ProfileStatus(2);

  constructor(status) {
    this.status = status;
  }
}

export class ProfileInviteStatus {
  static INVITED = "invited";
  static CONNECTED = "connected";
}
export class ProfileType {
  static Profile = "profile";
  static Candidat = "candidat";
  static Mandataire = "mandataire";
}
export class AncienneteStatus {
  static MONTH3 = "3mois";
  static MONTH6 = "6mois";
  static YEAR1 = "1an";
  static YEAR2 = "2ans";
  static YEAR2PLUS = "2ans+";
}
export class StatusPro {
  static ETUDIANT = "Etudiant";
  static CDI = "CDI";
  static CDD = "CDD";
  static INTERIM = "Interimaire";
  static FONCTIONNAIRE = "Fonctionnaire";
  static INDEPENDANT = "Travailleur indépendant";
  static RETRAITE = "Retraité";
  static SANSEMPLOI = "Sans emploi";
}

export function getProfileBaseSchema(withMandatoryFields) {
  return object({
    type: number().integer().required("Un type de personne est obligatoire"),
    mail: stringMax(250)
      .matches(
        "^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
        "Email invalide. Les caractères spéciaux sont interdits.",
      )
      .required("L'adresse e-mail est obligatoire "),
    prenom: stringMax(250).required("Le prénom est obligatoire"),
    nom: stringMax(250).required("Le nom est obligatoire"),
    nom_societe: stringMax(250).when("type", {
      is: 2,
      then: (schema) => schema.required("Le nom de la société est obligatoire"),
      otherwise: (schema) => schema.notRequired(),
    }),
    siret: stringMax(250).when("type", {
      is: 2,
      then: (schema) =>
        schema
          .required("Le numéro de SIRET est obligatoire")
          .max(15, "Le numéro de SIRET ne peut pas exceder 15 charactères"),
      otherwise: (schema) => schema.notRequired(),
    }),

    socType: string().when("type", {
      is: 2,
      then: (schema) => schema.required("Le type de société est obligatoire"),
      otherwise: (schema) => schema.notRequired(),
    }),
    pays: stringMax(250).required("Le pays est obligatoire"),
    adresse: stringMax(250).required("L'adresse est obligatoire"),
    cp: stringMax(10).required("Le code postal est obligatoire"),
    complement_adresse: stringMax(250).notRequired(""),
    ville: stringMax(250).required("La ville est obligatoire"),
    tel: withMandatoryFields
      ? stringMax(20).required()
      : stringMax(20).notRequired(),
  });
}

export const mandatairesFields = object({
  remuneration_type: stringMax(250).required(
    "Le type de rémuneration est obligatoire",
  ),
  remuneration_montant: number().required(
    "Le montant de la rémunération est obligatoire",
  ),
});

export function getMandataireProfileSchema(withMandatoryFields) {
  return mandatairesFields.concat(
    object({
      carte_g: stringMax(250).required("Le numéro de carte G est obligatoire"),
      profile: getProfileBaseSchema(withMandatoryFields),
      carte_delivree_par: stringMax(250).required(
        "L'organisme de délivrance de la carte G est obligatoire",
      ),
      carte_delivree_date: dateMax().required(
        "La date de délivrance de la carte est obligatoire",
      ),
      carte_mentions: array()
        .of(string())
        .required("Les mentions de la carte sont obligatoires"),
      nom_garant: stringMax(250),
      adresse_garant: stringMax(250),
      montant_garanti: number().integer(),
    }),
  );
}

export function getBailleurProfileSchema(withMandatoryFields) {
  return getProfileBaseSchema(withMandatoryFields).concat(
    object({
      sci_famille: boolean(),
      status_pro: stringMax(250),
    }),
  );
}

export function getTenantProfileSchema(withMandatoryFields) {
  return getBailleurProfileSchema(withMandatoryFields).concat(
    object({
      birthday: withMandatoryFields
        ? dateMax().when("type", {
            is: 1,
            then: (schema) =>
              schema.required("La date de naissance est obligatoire"),
            otherwise: (schema) => schema.notRequired(),
          })
        : dateMax().notRequired(),
      lieu_naissance: withMandatoryFields
        ? stringMax(250).when("type", {
            is: 1,
            then: (schema) =>
              schema.required("Le lieu de naissance est obligatoire"),
            otherwise: (schema) => schema.notRequired(),
          })
        : string().notRequired(),
      nationalite: withMandatoryFields
        ? stringMax(250).required("La nationalité est obligatoire")
        : stringMax(250).notRequired(),
      caution_type: stringMax(250).notRequired(),
      validite_visale: dateMax().notRequired(),
      loyer_max_visale: number().notRequired(),
      anciennete: stringMax(250).notRequired(),
      activite: stringMax(250).notRequired(),
      revenus_brut: number().notRequired(),
    }),
  );
}

export const PROFILE_INITIAL_VALUE = {
  type: "1",
  mail: "",
  prenom: "",
  nom: "",
  nom_societe: "",
  siret: "",
  pays: "France",
  complement_adresse: "",
  adresse: "",
  cp: "",
  ville: "",
  socType: "",
  sci_famille: false,
  activite: "",
  revenus_brut: "",
  birthday: "",
  lieu_naissance: "",
  tel: "",
  nationalite: "",
  garantors: [],
  files: [],
  caution_type: "",
  validite_visale: "",
  status_pro: "",
  contract_number: "",
  loyer_max_visale: "0",
  profiles: [],
  remuneration_type: "",
  remuneration_montant: "",
  anciennete: "",
};

export const MANDATAIRE_INITIAL_VALUE = {
  carte_g: "",
  remuneration_type: "",
  remuneration_montant: "",
  profile: { ...PROFILE_INITIAL_VALUE, type: "2" },
  carte_delivree_par: "",
  carte_delivree_date: "",
  carte_mentions: [],
  nom_garant: "",
  adresse_garant: "",
  montant_garanti: "",
};

export const PROFILE_NOM_INITIAL_VALUE = {
  nom_profile: "",
  profiles: [],
  remuneration_type: "",
  remuneration_montant: "",
  notes: "",
  cc_mail: "",
};

export const profileNomNomSchema = object({
  nom_profile: stringMax(250).required(" "),
  notes: string().optional(" "),
  cc_mail: string().optional(" "),
});

export const profileNomNomMandataireSchema =
  profileNomNomSchema.concat(mandatairesFields);
