import { PropertyStatus } from "../models/property";
import cloneDeep from "lodash/cloneDeep";

export function isPropertyDisabled(property) {
  if (!property) return false;
  return (
    property.abonnement_actif === false &&
    property.status === PropertyStatus.Actif.status
  );
}

export function buildPropertyFoldersTree(propertyFolders, properties) {
  const propertyFoldersById = Object.fromEntries(
    propertyFolders.map((pf) => {
      pf.properties = [];
      pf.property_folders = [];
      return [pf.id, pf];
    }),
  );
  for (let property of properties) {
    if (property.id_property_folder) {
      propertyFoldersById[property.id_property_folder]?.properties.push(
        property,
      );
    }
  }
  for (let pf of propertyFolders) {
    if (pf.id_parent) {
      propertyFoldersById[pf.id_parent]?.property_folders.push(pf);
    }
  }
  return cloneDeep({
    property_folders: propertyFolders.filter((pf) => !pf.id_parent),
    properties: properties.filter((p) => !p.id_property_folder),
  });
}

export function findPropertyFolder(propertyFolders, id) {
  if (!propertyFolders || propertyFolders.length === 0) return null;
  return (
    propertyFolders.find((pf) => pf.id === id) ??
    propertyFolders
      .map((pf) => findPropertyFolder(pf.property_folders, id))
      .find((pf) => pf !== null)
  );
}

export function filterPropertiesAndFoldersByStatus(
  propertiesAndFolders,
  status,
) {
  if (propertiesAndFolders === null) return null;

  const filtered = { ...propertiesAndFolders };
  filtered.properties =
    filtered.properties
      ?.filter((p) => p.status === status)
      .sort((p1, p2) =>
        p1.abonnement_actif === p2.abonnement_actif
          ? 0
          : p1.abonnement_actif
          ? -1
          : 1,
      ) ?? [];

  // Quand on cherche les actifs, si un sous-dossier est archivé, on sait deja qu'il n'a pas d'enfant actif
  // On peut deja filtrer tous les sous-dossiers qui n'ont pas le status spécifié
  if (status === PropertyStatus.Actif.status) {
    filtered.property_folders =
      filtered.property_folders?.filter((pf) => pf.status === status) ?? [];
  }

  // Dans les autres cas, on filtre recursivement les sous-dossiers
  filtered.property_folders =
    filtered.property_folders
      ?.map((pf) => filterPropertiesAndFoldersByStatus(pf, status))
      .filter((pf) => pf !== null) ?? [];

  // S'il n'y a aucun bien ou sous-dossier avec le status spécifié, et que son status n'est pas le bon,
  // on retourne null pour qu'il n'apparaisse pas
  if (
    filtered.property_folders.length === 0 &&
    filtered.properties.length === 0 &&
    filtered.status !== undefined &&
    filtered.status !== status
  ) {
    return null;
  }

  return filtered;
}

export function getIncludedPropertyAndFolderIds(propertyFolder) {
  const res = { propertyIds: new Set(), folderIds: new Set() };
  if (!propertyFolder) return res;

  res.propertyIds = new Set([...propertyFolder.properties.map((p) => p.id)]);
  res.folderIds.add(propertyFolder.id);

  for (let folder of propertyFolder.property_folders) {
    const { propertyIds, folderIds } = getIncludedPropertyAndFolderIds(folder);
    propertyIds.forEach((id) => res.propertyIds.add(id));
    folderIds.forEach((id) => res.folderIds.add(id));
  }

  return res;
}
