import { useState } from "react";
import StandaloneTextInputGroup from "./StandaloneTextInputGroup";
import { TenantStatus } from "../../models/tenant";
import ButtonCard from "./ButtonCard";
import { PropertyStatus } from "../../models/property";
import { BalanceDomain } from "../../utils/constants/balances";

export default function SelectPropertyTenantAction({
  tenants,
  properties,
  propertyFolders,
  filtering,
  onClick,
  title,
  text,
}) {
  const [filter, setFilter] = useState("");
  if (!tenants || !properties) {
    return null;
  }

  function filtered(list) {
    return list
      .filter((tenantOrProp) => {
        let tenant, property;
        if (filtering === "properties") {
          tenant = tenants.find((tenant) => tenantOrProp.id === tenant.id_bien);
          property = tenantOrProp;
        } else if (filtering === "tenants") {
          tenant = tenantOrProp;
          property = properties.find(
            (property) => tenantOrProp.id_bien === property.id,
          );
        }
        return (
          tenant?.profile_nom?.nom_profile.toLowerCase().includes(filter) ||
          property?.nom.toLowerCase().includes(filter) ||
          property?.rue.toLowerCase().includes(filter) ||
          property?.ville.toLowerCase().includes(filter)
        );
      })
      .map((tenantOrProp, i) => {
        let name1, name2, resourceType;
        if (filtering === "properties") {
          resourceType = BalanceDomain.PROPERTY;
          name1 = tenantOrProp.nom;
          name2 = tenants.findLast(
            (tenant) =>
              tenantOrProp.id === tenant.id_bien &&
              tenant.status !== TenantStatus.Candidat.status &&
              tenant.status !== TenantStatus.CandidatArchive.status,
          )?.profile_nom.nom_profile;
        } else if (filtering === "tenants") {
          resourceType = BalanceDomain.TENANT;
          name1 = tenantOrProp.profile_nom.nom_profile;
          name2 = properties.find(
            (property) => tenantOrProp.id_bien === property.id,
          )?.nom;
        }

        return (
          <ButtonCard
            className={"marginT5"}
            display={"block"}
            padding="sm"
            onClick={() => onClick(tenantOrProp.id, resourceType)}
            key={i}
          >
            {name1} - {name2}
          </ButtonCard>
        );
      });
  }

  let list, categoryTitle1, categoryTitle2, status1, status2;
  if (filtering === "properties") {
    list = properties;
    categoryTitle1 = "Biens actifs";
    status1 = PropertyStatus.Actif.status;
    categoryTitle2 = "Autres biens";
    status2 = PropertyStatus.Archive.status;
  } else if (filtering === "tenants") {
    list = tenants;
    categoryTitle1 = "Locataires actifs";
    status1 = TenantStatus.Actif.status;
    categoryTitle2 = "Autres locataires";
    status2 = TenantStatus.Archive.status;
  }

  return (
    <>
      {title && <h2 className={"padding0 text-center"}>{title}</h2>}
      <p className={"primaryText text-center"}>{text}</p>
      <div className={"marginT10 marginB10 flex justify-content-center"}>
        <StandaloneTextInputGroup
          onChange={(e) => setFilter(e.target.value.toLowerCase())}
          placeholder={"Cherchez un locataire ou un bien"}
        />
      </div>
      <p className={"primaryText"}>Dossiers</p>
      {propertyFolders
        ?.filter((folder) => folder.nom.toLowerCase().includes(filter))
        .map((propertyFolder, i) => (
          <ButtonCard
            className={"marginT5"}
            display={"block"}
            padding="sm"
            key={i}
            onClick={() => onClick(propertyFolder.id, BalanceDomain.FOLDER)}
          >
            {propertyFolder.nom}
          </ButtonCard>
        ))}
      <p className={"primaryText marginT10"}>{categoryTitle1}</p>
      {filtered(list.filter((tenantOrProp) => tenantOrProp.status === status1))}
      <p className={"primaryText marginT10 marginB10"}>{categoryTitle2}</p>
      {filtered(list.filter((tenantOrProp) => tenantOrProp.status === status2))}
    </>
  );
}
