import useResources from "./use-resources";
import useAuthenticatedAxios from "./use-authenticated-axios";
import { findAndUpdate } from "../utils";

const ROOT_PATH = "profiles";

export default function useProfileNoms() {
  const axios = useAuthenticatedAxios();

  const {
    resources: profileNoms,
    error,
    isLoading,
    create,
    update,
    del,
    mutate,
  } = useResources(ROOT_PATH);

  async function shareProfileNom(profileNomId, mail) {
    let newResource;
    await mutate(
      async (current) => {
        const response = await axios.post(`profiles/${profileNomId}/share`, {
          mail: mail,
        });
        newResource = response.data;
        return findAndUpdate(
          current,
          (resource) => resource.id === profileNomId,
          newResource,
        );
      },
      { revalidate: false },
    );
    return newResource;
  }

  async function deleteSharedProfileNom(profileNomId, mail) {
    let newResource;
    await mutate(
      async (current) => {
        const response = await axios.post(
          `profiles/${profileNomId}/share/delete`,
          {
            mail: mail,
          },
        );
        newResource = response.data;
        return findAndUpdate(
          current,
          (resource) => resource.id === profileNomId,
          newResource,
        );
      },
      { revalidate: false },
    );
    return newResource;
  }
  async function generateRecapFiscal(profileNomId, preview) {
    const response = await axios.post(`profiles/${profileNomId}/recap-fiscal`, {
      preview,
    });
    return response.data;
  }

  return {
    profileNoms,
    error,
    isLoading,
    addProfileNom: create,
    updateProfileNom: update,
    deleteProfileNom: del,
    shareProfileNom,
    deleteSharedProfileNom,
    generateRecapFiscal,
  };
}
