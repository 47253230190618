import CardAction from "../UI/CardAction";
import {
  AddPage,
  CalendarCheck,
  Caution,
  EDL,
  Message,
  Quittance,
  Refresh,
  RegulCharges,
  RevisionLoyer,
} from "../UI/Icons";
import styles from "./ActionsCourriers.module.css";
import { useModal } from "../../hooks/use-modal";
import { classnames } from "../../utils";
import QuittanceForm from "./Forms/QuittanceForm";
import { useEffect, useState } from "react";
import useTenants from "../../hooks/use-tenants";
import AvenantForm from "./Actions/AvenantForm";
import TenantAddForm, { TenantAddFormType } from "./Forms/TenantAddForm";
import { useImmer } from "use-immer";
import ProfileForm from "../Profile/ProfileForm";
import { ProfileStatus } from "../../models/profile";
import EdlRealisation from "../Edl/EdlRealisation";
import CautionForm from "./Actions/CautionForm";
import { useSearchParams } from "react-router-dom";

export default function ActionsCourriers({ tenant, property, balances }) {
  const [TenantQuittanceModal, , openTenantQuittanceModal] = useModal();
  const [
    AvenantModal,
    closeAvenantModal,
    openAvenantModal,
    getAvenantModalReady,
  ] = useModal();
  const [CautionModal, , openCautionModal] = useModal();
  const [EDLModal, closeEDLModal, openEDLModal] = useModal();

  const isAvenantModalReady = getAvenantModalReady();
  const [searchParams, setSearchParams] = useSearchParams();

  const [
    LeaseRegenerateFormModal,
    closeLeaseRegenerateFormModal,
    openLeaseRegenerateFormModal,
  ] = useModal();
  const [AddProfileModal, closeAddProfileModal, openAddProfileModal] =
    useModal();
  const [typeCourrier, setTypeCourrier] = useState();
  const [shortcut, setShortcut] = useState();
  const profiles = tenant?.profile_nom.profiles;
  const [profileToPush, setProfileToPush] = useImmer([]);
  useEffect(() => {
    if (profiles) {
      setProfileToPush(
        profiles.filter(
          (profile) =>
            profile.garantor_of === null &&
            profile.status === ProfileStatus.Actif.status,
        ),
      );
    }
  }, [profiles, setProfileToPush]);

  const { createCourrier } = useTenants();

  useEffect(() => {
    if (!isAvenantModalReady) {
      return;
    }
    if (searchParams.get("action") === "avenant") {
      setTypeCourrier("avenant");
      setShortcut(searchParams.get("avenant-type"));
      setSearchParams((params) => {
        const newParams = new URLSearchParams(params);
        newParams.delete("action");
        newParams.delete("avenant-type");
        return newParams;
      });
      openAvenantModal();
    }
  }, [isAvenantModalReady, openAvenantModal, searchParams, setSearchParams]);

  function profileSubmittedHandler(profile) {
    setProfileToPush((curr) => {
      curr.push(profile);
    });
  }

  function deleteProfileHandler(index) {
    setProfileToPush((curr) => {
      curr.splice(index, 1);
    });
  }

  return (
    <>
      <QuittanceForm
        tenantId={tenant?.id}
        ModalElement={TenantQuittanceModal}
      />
      <CautionForm tenant={tenant} profiles={profiles} Modal={CautionModal} />
      <AvenantForm
        balances={balances}
        Modal={AvenantModal}
        closeModal={closeAvenantModal}
        tenant={tenant}
        onSubmit={createCourrier}
        type={typeCourrier}
        openAddProfileModal={openAddProfileModal}
        profiles={profileToPush}
        onAddProfile={(profile) =>
          setProfileToPush((curr) => {
            curr.push(profile);
          })
        }
        onDeleteProfile={deleteProfileHandler}
        shortcut={shortcut}
        resetShortcut={() => setShortcut("")}
        property={property}
      />
      <ProfileForm
        onProfileSubmitted={(profile) => {
          profileSubmittedHandler(profile);
          closeAddProfileModal();
        }}
        addTenant
        modal={AddProfileModal}
      />
      <TenantAddForm
        regenerate
        closeModal={closeLeaseRegenerateFormModal}
        openAddProfileModal={openAddProfileModal}
        profileToPush={profileToPush.map((profile) => ({ profile }))}
        onAddProfile={(profile) =>
          setProfileToPush((curr) => {
            curr.push(profile.profile);
          })
        }
        onDeleteProfile={deleteProfileHandler}
        modal={LeaseRegenerateFormModal}
        tenant={tenant}
        propertyId={property?.id}
        type={TenantAddFormType.LOCATAIRE}
      />

      <EdlRealisation
        tenant={tenant}
        property={property}
        modal={EDLModal}
        onSubmitted={closeEDLModal}
      />
      <div className={classnames(styles.grid, styles.stroke)}>
        <CardAction
          icon={<Quittance />}
          text={
            "Envoyez une quittance à votre locataire pour attester du paiement du loyer"
          }
          title={"Générer une quittance"}
          onClick={openTenantQuittanceModal}
        />
        <CardAction
          icon={<RevisionLoyer />}
          text={
            "Indexez votre loyer sur l'indice de l'INSEE prévu par la clause de révision de votre contrat de bail"
          }
          title={"Faire une révision de loyer"}
          onClick={() => {
            setTypeCourrier("courrier");
            setShortcut("courrier_irl");
            openAvenantModal();
          }}
        />
        <CardAction
          icon={<EDL />}
          text={
            "Réaliser l’état des lieux d’entrée ou l’état des lieux de sortie de votre logement."
          }
          title={"Réaliser un état des lieux"}
          onClick={openEDLModal}
        />
        <CardAction
          icon={<AddPage />}
          text={
            "Envoyer un avenant au bail à votre locataire (changement de locataire, modification du loyer ou des charges, etc.)"
          }
          title={"Faire un avenant au bail"}
          onClick={() => {
            setTypeCourrier("avenant");
            openAvenantModal();
          }}
        />
        <CardAction
          icon={<Refresh size={"20"} />}
          text={
            "Permet de mettre à jour le document de bail, si vous modifiez un paramètre après sa génération."
          }
          title={"Regénérer le bail"}
          onClick={openLeaseRegenerateFormModal}
        />
        <CardAction
          icon={<CalendarCheck />}
          text={
            "Vous souhaitez mettre fin au bail de votre locataire ? Retrouvez le cadre légal, et nos modèles de courrier"
          }
          title={"Donnez congé à votre locataire"}
          onClick={() => {
            setTypeCourrier("conge");
            openAvenantModal();
          }}
        />
        <CardAction
          icon={<Message />}
          text={
            "Sélectionnez un modèle de courrier à envoyer à un intervenant dans le dossier (locataire, syndic, caution, huissier, etc.)"
          }
          title={"Envoyer un courrier"}
          onClick={() => {
            setTypeCourrier("courrier");
            openAvenantModal();
          }}
        />
        <CardAction
          icon={<RegulCharges />}
          text={
            "Envoyer au locataire la régularisation annuelle entre la provision de charge payée et les charges dues (ne s’applique pas aux forfaits de charge)"
          }
          title={"Régularisation de charge"}
          onClick={() => {
            setTypeCourrier("courrier");
            setShortcut("regul_charge");
            openAvenantModal();
          }}
        />
        <CardAction
          icon={<Caution />}
          text={
            "Sécurisez votre location et faites garantir le paiement du loyer à un garant qui va se porter caution."
          }
          title={"Acte de cautionnement"}
          onClick={() => {
            openCautionModal();
          }}
        />
      </div>
    </>
  );
}
