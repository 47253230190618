import { useRef, useState } from "react";
import styles from "./Dropdown.module.css";

export default function Dropdown({ header, body, type, className }) {
  const dialogRef = useRef();
  const [zIndex, setZIndex] = useState(null);
  let timeout = null;

  function open() {
    setZIndex(100);
    dialogRef.current?.show();
  }

  function close() {
    setZIndex(null);
    dialogRef.current?.close();
  }

  return (
    <div className={className}>
      <div
        className={styles.menu}
        style={{ zIndex }}
        onMouseLeave={() => (timeout = setTimeout(close, 400))}
        onMouseEnter={() => clearTimeout(timeout)}
      >
        <div
          onMouseOver={() => type === "hover" && open()}
          onClick={() => type === "click" && open()}
        >
          {header}
        </div>
        <dialog className={styles.dialog} ref={dialogRef}>
          <div className={styles.dropDown}>{body}</div>
        </dialog>
      </div>
    </div>
  );
}
