import { useCallback, useId, useRef } from "react";
import Modal from "../components/UI/Modal";
import Button from "../components/UI/Button";

const OK = "ok";
const CANCEL = "cancel";

export function useConfirm() {
  const modalRef = useRef();
  const id = useId();
  const inputRef = useRef();
  const openModal = useCallback((input) => {
    modalRef.current.open();
    inputRef.current = input;
  }, []);

  const confirm = useRef(({ children, onAnswer, ...props }) => (
    <Modal
      noBackdropClose
      key={id}
      {...props}
      ref={modalRef}
      onDialogSubmit={(returnValue) => {
        if (returnValue === OK) {
          onAnswer(true, inputRef.current);
        } else {
          onAnswer(false, inputRef.current);
        }
        inputRef.current = null;
      }}
    >
      <form method="dialog">
        <div className={"marginB10"}>{children}</div>
        <Button type="submit" buttonStyle="secondary" value={CANCEL}>
          Annuler
        </Button>
        <Button
          className={"marginL10 paddingL20 paddingR20"}
          type="submit"
          buttonStyle="primary"
          value={OK}
        >
          OK
        </Button>
      </form>
    </Modal>
  ));

  return [confirm.current, openModal];
}
