export default function DisplayAddress({ profile }) {
  return (
    <>
      <p>
        {profile.prenom} {profile.nom} {profile.garantor_of ? "(Garant)" : ""}
      </p>
      {profile.complement_adresse ? <p>{profile.complement_adresse}</p> : ""}
      <p>{profile.adresse}</p>
      <p>
        {profile.cp} {profile.ville}
      </p>
    </>
  );
}
