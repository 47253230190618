import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, useLocation } from "react-router-dom";
import App from "./App";
import "./index.css";

import { AuthProvider } from "./store/auth-context";
import { StripeProvider } from "./components/Stripe/Stripe";
import TagManager from "react-gtm-module/dist/TagManager";
import ReactPixel from "react-facebook-pixel";

const CLIENT_TOKEN = "pubbb0c366a67abbd9e787001f8a14887c0";
const APPLICATION_ID = "36ee2667-ebc3-41eb-ae25-d31d8fed500d";
const DATADOG_SITE = "datadoghq.com";
const SERVICE = "qalimo";
const VERSION = process.env.REACT_APP_VERSION;
const ENV = process.env.REACT_APP_ENV;

datadogLogs.init({
  clientToken: CLIENT_TOKEN,
  site: DATADOG_SITE,
  service: SERVICE,
  env: ENV,
  version: VERSION,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
});

datadogRum.init({
  applicationId: APPLICATION_ID,
  clientToken: CLIENT_TOKEN,
  site: DATADOG_SITE,
  service: SERVICE,
  env: ENV,
  version: VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  allowedTracingUrls: [
    (url) => url.startsWith(process.env.REACT_APP_BACKEND_URL),
  ],
});

datadogRum.startSessionReplayRecording();

const tagManagerArgs = {
  gtmId: "GTM-PQHNWRZN",
};
TagManager.initialize(tagManagerArgs);

const options = {
  autoConfig: false,
  debug: false,
};
ReactPixel.init("583222351047108", {}, options);

export function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    ReactPixel.pageView();
  }, [location]);
}

createRoot(document.getElementById("root")).render(
  <StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <StripeProvider>
          <App />
        </StripeProvider>
      </BrowserRouter>
    </AuthProvider>
  </StrictMode>,
);
