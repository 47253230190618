import { PropertyStatus } from "../../models/property";
import Tabs from "../UI/Tabs";
import usePropertyFolders from "../../hooks/use-property-folders";
import PropertyList from "./PropertyList";
import {
  buildPropertyFoldersTree,
  filterPropertiesAndFoldersByStatus,
} from "../../utils/properties";

export const ROOT_PATH = "/bailleur/properties/";

export default function PropertyListShow({ properties, onDuplicateProperty }) {
  const { propertyFolders } = usePropertyFolders();

  const propertiesAndFolders = buildPropertyFoldersTree(
    propertyFolders,
    properties,
  );

  const tousMesBiens = filterPropertiesAndFoldersByStatus(
    propertiesAndFolders,
    PropertyStatus.Actif.status,
  );
  const archivedProperties = properties.filter(
    (property) => property.status === PropertyStatus.Archive.status,
  );
  const archive = filterPropertiesAndFoldersByStatus(
    propertiesAndFolders,
    PropertyStatus.Archive.status,
  );

  return (
    <Tabs id="prop-list">
      <div className="container bg-blue paddingT10 border-blue">
        <div className="content">
          <Tabs.TabList>
            <Tabs.Tab panelId="tous-mes-biens">Tous mes biens</Tabs.Tab>
            <Tabs.Tab panelId="archives">
              Archivés({archivedProperties.length})
            </Tabs.Tab>
          </Tabs.TabList>
        </div>
      </div>
      <div className="blueSpace"></div>
      <div className="content">
        <Tabs.TabPanel id="tous-mes-biens">
          <PropertyList
            propertiesAndFolders={tousMesBiens}
            onDuplicateProperty={onDuplicateProperty}
          />
        </Tabs.TabPanel>
        <Tabs.TabPanel id="archives">
          <PropertyList
            propertiesAndFolders={archive}
            onDuplicateProperty={onDuplicateProperty}
          />
        </Tabs.TabPanel>
      </div>
    </Tabs>
  );
}
