import Card from "../UI/Card";
import InfoBoxList from "../UI/InfoBoxList";
import style from "./TenantSynthese.module.css";
import { FileStatus } from "../../models/documents";
import useFiles from "../../hooks/use-files";
import moment from "moment";
import { currencyFormatter } from "../../api/Functions";
import { Interrogation } from "../UI/Icons";
import Tooltip from "../UI/Tooltip";
import DocumentsFormUpload from "../File/DocumentsFormUpload";
import { useState } from "react";
import { useModal } from "../../hooks/use-modal";

function TenantSynthese({
  tenant,
  balanceTotal,
  totalPercus,
  DGPercu,
  DGAppele,
  dateLastVirement,
}) {
  const { files } = useFiles({ tenantId: tenant?.id });
  const [DocumentModal, closeDocumentModal, openDocumentModal] = useModal();
  const [typeDocument, setTypeDocument] = useState("");

  function checkFiles(tag) {
    const fileswithTag = files.filter((file) => file.tag === tag);
    let color;
    let text;
    if (fileswithTag.length > 0) {
      if (
        fileswithTag.filter((file) =>
          moment(file.valid_until).isAfter(moment()),
        ).length < 1 &&
        fileswithTag.filter((file) => file.valid_until).length > 0
      ) {
        color = "red";
        text = "Expiré";
      } else if (
        fileswithTag.filter((file) => file.status === FileStatus.valide.status)
          .length > 0
      ) {
        color = "green";
        text = tag === "assurance_habitation" ? "Valide" : "Signé";
      } else {
        color = "yellow";
        text = "A valider";
      }
    } else {
      color = "red";
      text = "Absent";
    }
    return [color, text];
  }

  const [colorAssuranceHabitation, textAssuranceHabitation] = checkFiles(
    "assurance_habitation",
  );
  const [colorEdl, textEdl] = checkFiles("edl");
  let colorBail;
  let textBail;
  if (tenant.preavis) {
    colorBail = "purple";
    textBail = "En préavis";
  } else {
    [colorBail, textBail] = checkFiles("bail");
  }

  return (
    <>
      <DocumentModal>
        <DocumentsFormUpload
          tenant={tenant}
          closeDocumentModal={closeDocumentModal}
          isBailleur
          typeDocument={typeDocument}
        />
      </DocumentModal>
      <Card className={style.wrapper}>
        <p className={`${style.header} cardTitle`}>Synthèse</p>
        <p>Assurance habitation</p>
        <InfoBoxList
          color={colorAssuranceHabitation}
          text={textAssuranceHabitation}
          onClick={
            textAssuranceHabitation === "Absent"
              ? () => {
                  setTypeDocument("assurance_habitation");
                  openDocumentModal();
                }
              : undefined
          }
        />
        <p>Bail</p>
        <InfoBoxList
          color={colorBail}
          text={textBail}
          onClick={
            textBail === "Absent"
              ? () => {
                  setTypeDocument("bail");
                  openDocumentModal();
                }
              : undefined
          }
        />
        <p>Etat des lieux</p>
        <InfoBoxList
          color={colorEdl}
          text={textEdl}
          onClick={
            textEdl === "Absent"
              ? () => {
                  setTypeDocument("edl");
                  openDocumentModal();
                }
              : undefined
          }
        />
        <p>Balance</p>
        {balanceTotal < 0 ? (
          <InfoBoxList
            color={
              balanceTotal < (tenant.loyer_hc + tenant.charges) * -1
                ? "red"
                : "yellow"
            }
            text={currencyFormatter(balanceTotal)}
          />
        ) : (
          <InfoBoxList color={"green"} text={currencyFormatter(balanceTotal)} />
        )}
        <p>Loyers percus</p>
        <InfoBoxList color={"green"} text={currencyFormatter(totalPercus)} />
        <div>
          DG perçus{" "}
          <Tooltip
            content={"Dépôt de garantie perçu / dépôt de garantie appelé"}
          >
            <Interrogation />
          </Tooltip>
        </div>
        <InfoBoxList
          color={DGPercu >= DGAppele ? "green" : "red"}
          text={`${Math.round(DGPercu)}/${currencyFormatter(DGAppele)}`}
        />
        <p>Dernier versement</p>
        <InfoBoxList
          text={moment(dateLastVirement).format("DD/MM/YYYY") ?? ""}
        />
      </Card>
    </>
  );
}

export default TenantSynthese;
