import {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./Modal.module.css";
import { Croix } from "./Icons";
import { Link } from "react-router-dom";
import { classnames } from "../../utils";
import { createPortal } from "react-dom";

const CANCEL = "cancel";

const ModalContext = createContext({});

function isMouseOutsideRect(e, rect) {
  // hack for firefox, that registers a click on select dropdowns, with coordinates 0
  if (e.target.nodeName === "OPTION") {
    return false;
  }
  return (
    e.clientX < rect.left ||
    e.clientX > rect.right ||
    e.clientY < rect.top ||
    e.clientY > rect.bottom
  );
}

const Modal = forwardRef(function (props, ref) {
  const {
    header,
    stickyHeader,
    children,
    routed = false,
    className,
    noBackdropClose,
    noPadding,
    onCancel,
    onClose,
    onDialogSubmit,
    size,
    blueHeader,
    maxContentWidth,
  } = props;
  const dialogRef = useRef();
  /* hack for session replay to display modal */
  const zIndex = useRef(1000);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [mouseDownOutside, setMouseDownOutside] = useState(false);

  const open = useCallback(() => {
    setDialogOpen(true);
    dialogRef.current.showModal();
    zIndex.current += 1000;
  }, []);

  const close = useCallback(() => {
    dialogRef.current.close();
  }, []);

  useImperativeHandle(ref, () => ({ open, close }), [close, open]);

  useEffect(() => {
    const dialog = dialogRef.current;
    const dialogDimensions = () => dialogRef.current.getBoundingClientRect();

    function clickHandler(e) {
      // Close dialog if click outside in the backdrop
      if (
        !noBackdropClose &&
        isMouseOutsideRect(e, dialogDimensions()) &&
        mouseDownOutside
      ) {
        // Click outside is a cancel
        cancelModal();
      }
      setMouseDownOutside(false);
    }

    function mouseDownHandler(e) {
      // Remember when mouseDown is done outside dialog
      if (isMouseOutsideRect(e, dialogDimensions())) {
        setMouseDownOutside(true);
      } else {
        setMouseDownOutside(false);
      }
    }

    function closeHandler(e) {
      if (onDialogSubmit) {
        onDialogSubmit(dialog.returnValue);
      }
      if (onClose) {
        onClose(e);
      }
      setDialogOpen(false);
      zIndex.current -= 1000;
    }

    function cancelHandler(e) {
      if (onCancel) {
        onCancel(e);
      }
    }

    dialog.addEventListener("cancel", cancelHandler);
    dialog.addEventListener("close", closeHandler);
    dialog.addEventListener("click", clickHandler);
    dialog.addEventListener("mousedown", mouseDownHandler);

    // If the modal is routed, we need to open it automatically
    if (routed && !dialogOpen) {
      open();
    }

    // Cleanup function, remove event listeners
    return () => {
      dialog.removeEventListener("cancel", cancelHandler);
      dialog.removeEventListener("close", closeHandler);
      dialog.removeEventListener("click", clickHandler);
      dialog.removeEventListener("mousedown", mouseDownHandler);
    };
  }, [
    mouseDownOutside,
    noBackdropClose,
    onCancel,
    onClose,
    onDialogSubmit,
    routed,
    dialogOpen,
    open,
  ]);

  function cancelModal() {
    const shouldClose = dialogRef.current.dispatchEvent(
      new Event("cancel", { cancelable: true }),
    );
    if (shouldClose) {
      dialogRef.current.close(CANCEL);
    }
  }

  return (
    <ModalContext.Provider value={{ cancelModal, zIndex }}>
      {createPortal(
        <dialog
          className={classnames(
            className,
            styles.modal,
            styles[size],
            noPadding && styles.noPadding,
          )}
          ref={dialogRef}
          style={{ zIndex: zIndex.current }}
        >
          {dialogOpen && (
            <div
              className={classnames(
                styles.content,
                styles[`mcw-${maxContentWidth}`],
              )}
            >
              <div
                className={classnames(
                  styles.header,
                  stickyHeader && styles.sticky,
                  blueHeader && styles.blueHeader,
                )}
              >
                {header}
                <div
                  className={styles.close}
                  onClick={() => {
                    // Click on the cross is a cancel
                    cancelModal();
                  }}
                >
                  <Croix />
                </div>
              </div>

              <div className={styles.body}>{children}</div>
            </div>
          )}
        </dialog>,
        document.body,
      )}
    </ModalContext.Provider>
  );
});

export function ModalAwareLink({ children, ...rest }) {
  const { cancelModal } = useContext(ModalContext);
  return (
    <Link {...rest} onClick={cancelModal}>
      {children}
    </Link>
  );
}

export default Modal;
