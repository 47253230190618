import useAuthenticatedAxios from "./use-authenticated-axios";
import useAuth from "./use-auth";
import useSWR from "swr";
import { useCallback, useMemo } from "react";
import useSWRImmutable from "swr/immutable";

const ROOT_PATH = "account";

export default function useAccount() {
  const axios = useAuthenticatedAxios();
  const { auth } = useAuth();
  const fallbackData = useMemo(() => {
    return {
      partner_codes: [],
    };
  }, []);
  const {
    data: account,
    error,
    isLoading,
    mutate,
  } = useSWR(
    ROOT_PATH ? { url: ROOT_PATH, userId: auth.userId } : null,
    ({ url }) => {
      return axios.get(url).then((res) => res.data);
    },
    { fallbackData },
  );

  const { data: filleuls } = useSWRImmutable(`${ROOT_PATH}/filleuls`, (url) => {
    return axios.get(url).then((res) => res.data);
  });

  async function changePassword(values) {
    return mutate(
      async () => {
        await axios.put(`change-password`, { ...values });
      },
      { revalidate: false },
    );
  }

  async function updateCompte(resource) {
    let newResource;
    await mutate(
      async () => {
        const response = await axios.put(ROOT_PATH, resource);
        newResource = response.data;
        return response.data;
      },
      { revalidate: false },
    );
    return newResource;
  }

  const subscribe = useCallback(
    async (values, preview = false) => {
      if (preview) {
        return axios.post("subscribe", values);
      }
      let newResource;
      await mutate(
        async () => {
          const response = await axios.post("subscribe", values);
          newResource = response.data;
          return response.data;
        },
        { revalidate: false },
      );
      return newResource;
    },
    [axios, mutate],
  );

  async function unsubscribe() {
    let newResource;
    await mutate(
      async () => {
        const response = await axios.delete("unsubscribe");
        newResource = response.data;
        return response.data;
      },
      { revalidate: false },
    );
    return newResource;
  }

  return {
    account,
    filleuls: filleuls || {},
    hasValidAddress:
      account.adresse && account.ville && account.cp && account.nom,
    error,
    isLoading,
    changePassword,
    updateCompte,
    subscribe,
    unsubscribe,
    refresh: () => mutate(),
  };
}
