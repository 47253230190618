import Card from "../UI/Card";
import { currencyFormatter } from "../../api/Functions";

export default function Partenaires({ partnerCodes }) {
  return partnerCodes.map((partnerCode) => (
    <Card key={partnerCode.code}>
      <p className={"cardTitle"}>Code : {partnerCode.code}</p>
      <p className={"marginT10"}>
        Montant à facturer :{" "}
        {currencyFormatter(partnerCode.montant_a_payer / 100)}
      </p>
      <p>
        Montant total perçu :{" "}
        {currencyFormatter(partnerCode.montant_total / 100)}
      </p>
    </Card>
  ));
}
