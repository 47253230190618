import { useEffect } from "react";
import { currencyFormatter } from "../../api/Functions";
import { RECOMMANDE_PRICE } from "../../models/signatureElectronique";
import styles from "../ElectronicSigning/ElectronicSigning.module.css";
import useProfileNoms from "../../hooks/use-profile-noms";
import useProperties from "../../hooks/use-properties";
import DisplayAddress from "../UI/DisplayAddress";
import BuyCredits from "../Stripe/BuyCredits";
import useAccount from "../../hooks/use-account";
import DocumentDownloadLink from "../UI/DocumentDownloadLink";
import { DocType } from "../../utils/constants/documents";
import DocumentThumbnail from "../UI/DocumentThumbnail";
import RadioGroup from "../UI/RadioGroup";
import { useFormikContext } from "formik";
import Radio from "../UI/Radio";

export default function SendRecommande({ tenant, file }) {
  const { properties } = useProperties();
  const { profileNoms } = useProfileNoms();
  const { account, refresh: refreshAccount } = useAccount();
  const { setFieldValue } = useFormikContext();

  const property = properties.find(
    (property) => property.id === tenant?.id_bien,
  );

  const profileNom = profileNoms.find(
    (profileNom) => profileNom.id === property?.id_profile_nom_owner,
  );

  useEffect(() => {
    (async () => {
      await setFieldValue(
        "id_expediteur",
        profileNom?.profiles[0].id.toString(),
      );
      await setFieldValue(
        "id_destinataire",
        tenant?.profile_nom.profiles[0].id.toString(),
      );
    })();
  }, [profileNom, setFieldValue, tenant]);

  if (!profileNom || !property || !tenant) return null;

  return (
    <>
      <h2 className={"text-center"}>Envoyer un courrier recommandé</h2>
      <BuyCredits
        account={account}
        refreshAccount={refreshAccount}
        buyCredit={account.credits < RECOMMANDE_PRICE}
      />

      {file && (
        <>
          <p className={"primaryText marginT20"}>Document à envoyer : </p>
          <div className={"marginT10"}>
            <DocumentDownloadLink
              documentId={file.id}
              doctype={DocType.GENERIC_FILE}
              file={file}
            >
              <DocumentThumbnail
                documentId={file.id}
                file={file}
                doctype={DocType.GENERIC_FILE}
              />
            </DocumentDownloadLink>
          </div>
        </>
      )}
      <div className={styles.grid}>
        <div>
          <p className={"primaryText marginB10"}>Emetteur :</p>
          {profileNom.profiles.map((profile, i) => (
            <RadioGroup className={"marginT10"} styling={"button"} key={i}>
              <Radio
                name="id_expediteur"
                label={<DisplayAddress profile={profile} />}
                value={profile.id.toString()}
              />
            </RadioGroup>
          ))}
        </div>
        <div>
          <p className={"primaryText marginB10"}>Destinataire :</p>
          {tenant?.profile_nom.profiles.map((profile, i) => (
            <RadioGroup className={"marginT10"} styling={"button"} key={i}>
              <Radio
                name="id_destinataire"
                label={<DisplayAddress profile={profile} />}
                value={profile.id.toString()}
              />
            </RadioGroup>
          ))}
        </div>
      </div>

      <p className={"text-center secondaryText marginB10 marginT20"}>
        Le courrier recommandé sera facturée{" "}
        {currencyFormatter(RECOMMANDE_PRICE)} TTC sur vos crédits disponibles.
      </p>
    </>
  );
}
