import { ROOT_PATH } from "../api/Properties";
import useSWR from "swr";
import useAuthenticatedAxios from "./use-authenticated-axios";
import useAuth from "./use-auth";
import { useMemo } from "react";
import useSWRImmutable from "swr/immutable";

export function usePublicProperties() {
  const axios = useAuthenticatedAxios();
  const {
    data: publicProperties,
    error,
    isLoading,
  } = useSWRImmutable("/biens/public", (url) => {
    return axios.get(url).then((res) => res.data);
  });
  return {
    publicProperties,
    error,
    isLoading,
  };
}

export function usePublicProperty(propertyId) {
  const axios = useAuthenticatedAxios();
  const {
    data: property,
    error,
    isLoading,
  } = useSWRImmutable(
    propertyId ? `/biens/${propertyId}/public` : null,
    (url) => {
      return axios.get(url).then((res) => res.data);
    },
  );
  return {
    property,
    error,
    isLoading,
  };
}
export function useAllProperties() {
  const axios = useAuthenticatedAxios();
  const {
    data: properties,
    error,
    isLoading,
  } = useSWRImmutable("/biens/admin", (url) => {
    return axios.get(url).then((res) => res.data);
  });
  return {
    properties,
    error,
    isLoading,
  };
}

export default function useProperties(options = {}) {
  const { noFetch = false, params = {}, withById = false } = options;
  const axios = useAuthenticatedAxios();
  const { auth } = useAuth();
  const fallbackData = useMemo(() => [], []);

  const {
    data: properties,
    error,
    isLoading,
    mutate,
  } = useSWR(
    [ROOT_PATH, auth.userId, options],
    ([url]) => {
      if (noFetch) return Promise.resolve([]);
      return axios
        .get(url, {
          params,
          paramsSerializer: {
            indexes: null, // no brackets at all
          },
        })
        .then((res) => res.data);
    },
    { fallbackData },
  );

  function addProperty(property) {
    return mutate(
      async (currentProperties) => {
        const response = await axios.post(ROOT_PATH, property);
        return [...currentProperties, response.data];
      },
      { revalidate: false },
    );
  }

  function duplicateProperty(property) {
    return mutate(
      async (currentProperties) => {
        const response = await axios.post(
          `${ROOT_PATH}/${property.id}/duplicate`,
        );
        return [...currentProperties, response.data];
      },
      { revalidate: false },
    );
  }

  function deleteProperty(propertyId) {
    return mutate(
      async (currentProperties) => {
        await axios.delete(`${ROOT_PATH}/${propertyId}`);

        return currentProperties.filter(
          (property) => property.id !== propertyId,
        );
      },
      { revalidate: false },
    );
  }

  function updateProperty(propertyId, property) {
    return mutate(
      async (currentProperties) => {
        const response = await axios.put(
          `${ROOT_PATH}/${propertyId}`,
          property,
        );
        const propertyIndex = currentProperties.findIndex(
          (property) => property.id === propertyId,
        );
        if (propertyIndex === -1) {
          throw new Error("Bien non trouvé");
        }
        const newProperties = [...currentProperties];
        newProperties[propertyIndex] = response.data;
        return newProperties;
      },
      { revalidate: false },
    );
  }

  const propertiesById = useMemo(() => {
    if (!withById) return {};
    return properties.reduce((acc, property) => {
      acc[property.id] = property;
      return acc;
    }, {});
  }, [properties, withById]);

  return {
    properties,
    propertiesById,
    error,
    isLoading,
    addProperty,
    updateProperty,
    deleteProperty,
    duplicateProperty,
  };
}
