import { axiosPublic } from "../../api";
import { Form, Formik } from "formik";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { object, ref, string } from "yup";
import TextInput from "../UI/TextInput";
import styles from "./AuthForm.module.css";
import RadioGroup from "../UI/RadioGroup";
import Radio from "../UI/Radio";
import { useState } from "react";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import { stringMax } from "../../models/utils";

function AuthForm() {
  const nav = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const idParrain = searchParams.get("referral") || location.state?.referral;

  async function signMeUp(values) {
    try {
      const data = {
        mail: values.mail,
        password: values.password,
        home: values.home,
        id_parrain: idParrain,
        telephone: values.telephone,
      };
      await axiosPublic.post("/signup", data);
      nav("verify?mail=" + values.mail, {
        replace: true,
        state: { from: location.state?.from },
      });
    } catch (error) {
      if (!error?.response) {
        setErrMsg("Serveur de connexion injoignable");
      } else if (error.response?.status === 409) {
        setErrMsg("Adresse e-mail déjà utilisée");
      } else {
        setErrMsg("Erreur lors de la création de compte");
        console.error(error);
      }
    }
  }

  return (
    <Formik
      className={styles.form}
      initialValues={{
        mail: "",
        pro: "/bailleur",
        password: "",
        dPassword: "",
        home: "/bailleur",
        telephone: "",
      }}
      validationSchema={object({
        telephone: stringMax(250).when("home", {
          is: "/bailleur",
          then: stringMax(250).required("Numéro de téléphone requis"),
          otherwise: stringMax(250).notRequired(),
        }),
        mail: string()
          .matches(
            "^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
            "Email invalide. Les caractères spéciaux interdits",
          )
          .required("Adresse e-mail requise "),
        password: string().required(" "),
        dPassword: string()
          .oneOf([ref("password")], "Les mots de passe doivent être identiques")
          .required(" "),
      })}
      onSubmit={signMeUp}
    >
      {({ setFieldValue, values }) => {
        return (
          <Form className={styles.form}>
            <TextInput
              type="email"
              name="mail"
              placeholder="Adresse e-mail"
              label={"Adresse e-mail"}
            />
            {values.home === "/bailleur" && (
              <TextInput
                type="text"
                name="telephone"
                placeholder="0601323123"
                label={"Numéro de téléphone"}
              />
            )}
            <TextInput
              type="password"
              name="password"
              placeholder="Mot de passe"
              label={"Mot de passe"}
            />
            <TextInput
              type="password"
              name="dPassword"
              placeholder="Mot de passe"
              label={"Confirmer le mot de passe"}
            />
            Êtes vous une agence / mandataire ou un particulier ?
            <RadioGroup onChange={(e) => setFieldValue("home", e.target.value)}>
              <Radio name={"pro"} value="/pro" label="Mandataire" />
              <Radio name={"pro"} value="/bailleur" label="Particulier" />
            </RadioGroup>
            {values.pro === "/bailleur" && (
              <>
                Vous utiliserez principalement Qalimo en tant que
                <RadioGroup>
                  <Radio name="home" value="/bailleur" label="Bailleur" />
                  <Radio name="home" value="/locataire" label="Locataire" />
                </RadioGroup>
              </>
            )}
            {errMsg && <p className={styles.error}>{errMsg}</p>}
            <FormikSubmitButton>S'enregistrer</FormikSubmitButton>
            <div className={styles.rememberMe}>
              <p>Déjà un compte ?</p>
              <div>
                <Link
                  to="/login"
                  state={{
                    from: location.state?.from ?? location,
                    referral: idParrain,
                  }}
                >
                  Connectez vous
                </Link>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default AuthForm;
