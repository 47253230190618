import IconAndText from "../UI/IconAndText";
import { Edit, Evenements, Plus } from "../UI/Icons";
import { Table } from "../UI/Table";
import moment from "moment";
import useEvents from "../../hooks/use-events";
import { useState } from "react";
import { useModal } from "../../hooks/use-modal";
import FormEvent from "./FormEvent";
import { sanitizeValues } from "../../models/utils";
import ButtonAction from "../UI/ButtonAction";
import { produce } from "immer";

export default function Events() {
  const { events, updateEvent, addEvent, deleteEvent } = useEvents();
  const [eventToEdit, setEventToEdit] = useState();
  const [FormModal, closeForm, openForm] = useModal();

  async function submitHandler(values) {
    const sanitizedValues = produce(sanitizeValues(values), (draft) => {
      draft.debut = moment(draft.debut).format("YYYY-MM-DD HH:mm");
      draft.fin = moment(draft.fin).format("YYYY-MM-DD HH:mm");
    });
    if (eventToEdit) {
      await updateEvent(eventToEdit.id, sanitizedValues);
    } else {
      await addEvent(sanitizedValues);
    }
    closeForm();
  }

  async function deleteHandler(id) {
    await deleteEvent(id);
    closeForm();
    setEventToEdit(null);
  }

  return (
    <>
      <FormModal size={"small"}>
        <FormEvent
          event={eventToEdit}
          onSubmit={submitHandler}
          onDelete={deleteHandler}
        />
      </FormModal>
      <div className={"flex space-between"}>
        <IconAndText
          icon={<Evenements stroke={"#9D9D9D"} />}
          text={<h2 className={"margin0"}>Evènements</h2>}
        />
        <ButtonAction
          onClick={openForm}
          logo={<Plus size={"20"} />}
          tooltip={"Ajouter une entrée"}
        />
      </div>
      <Table className="marginT20">
        <Table.Header>
          <Table.Column>Date</Table.Column>
          <Table.Column>Heure</Table.Column>
          <Table.Column>Evènement</Table.Column>
        </Table.Header>
        <Table.Body>
          {events.length > 0 ? (
            events.map((event, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  {moment(event.debut).format("DD/MM/YYYY")}
                </Table.Cell>
                <Table.Cell>
                  {moment(event.debut).format("HH:mm")}
                  {" - "}
                  {moment(event.fin).format("HH:mm")}
                </Table.Cell>
                <Table.Cell>{event.subject}</Table.Cell>
                <Table.Cell>
                  <span
                    className={"cursorPointer"}
                    onClick={() => {
                      setEventToEdit(event);
                      openForm();
                    }}
                  >
                    <Edit />
                  </span>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan="100%">
                <div className={"text-center secondaryText marginT20"}>
                  Aucun évènement à venir
                </div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
}
