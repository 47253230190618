import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import BankAdd from "./components/Bank/BankAdd";
import BankForm from "./components/Bank/BankForm";
import DashboardPage from "./pages/DashboardPage";
import FinancesRootPage from "./pages/banks/BankRootPage";
import ForgottenPassword from "./pages/authentification/ForgottenPassword";
import ForgottenPasswordReconf from "./pages/authentification/ForgottenPasswordReconf";
import LoginPage from "./pages/authentification/LoginPage";
import NotFound from "./pages/NotFound";
import PropertyShowPage from "./pages/properties/PropertyShowPage";
import SignupPage from "./pages/authentification/SignupPage";
import SignupVerifyPage from "./pages/authentification/SignupVerifyPage";
import TenantsRootPage from "./pages/tenants/RootPage";
import TenantShowPage from "./pages/tenants/ShowPage";
import TenantListPage from "./pages/tenants/ListPage";
import TenantEditPage from "./pages/tenants/EditPage";
import TenantAddGarantPage from "./pages/tenants/AddGarantPage";
import ProfileAddPage from "./pages/profiles/ProfileAddPage";
import ProfileEditPage from "./pages/profiles/ProfileEditPage";
import CandidatRootPage from "./pages/candidats/CandidatRootPage";
import CandidatShowPage from "./pages/candidats/CandidatShowPage";
import PropertyRootPage from "./pages/properties/PropertyRootPage";
import PropertyAddPage from "./pages/properties/PropertyAddPage";
import PropertyEditPage from "./pages/properties/PropertyEditPage";
import PropertyListPage from "./pages/properties/PropertyListPage";
import LocataireRootPage from "./pages/locataire/LocataireRootPage";
import LocataireListPage from "./pages/locataire/LocataireListPage";
import LocataireShowPage from "./pages/locataire/LocataireShowPage";
import ProfilesNomAddPage from "./pages/profiles/ProfilesNomAddPage";
import ProfilesNomEditPage from "./pages/profiles/ProfilesNomEditPage";
import ProfilesBailleurRootPage from "./pages/profiles/ProfilesBailleurRootPage";
import TenantAddPage from "./pages/tenants/TenantAddPage";
import RequireAuth from "./components/Auth/RequireAuth";
import useAuth from "./hooks/use-auth";
import PersistLogin from "./components/Auth/PersistLogin";
import LocataireListCandidaturesPages from "./pages/locataire/LocataireListCandidaturesPages";
import AdminPage from "./pages/AdminPage";
import CheckoutReturn from "./components/Stripe/CheckoutReturn";
import CandidatListPage from "./pages/tenants/CandidatListPage";
import PropertyFolderShowPage from "./pages/propertyFolder/PropertyFolderShowPage";
import MonComptePage from "./pages/MonComptePage";
import { useEffect } from "react";
import BankSyncForm from "./components/Bank/BankSyncForm";
import BankSyncTransactions from "./components/Bank/BankSyncTransactions";
import { TenantAddFormType } from "./components/Tenant/Forms/TenantAddForm";
import useFrontVersion from "./hooks/use-front-version";
import Button from "./components/UI/Button";
import { usePageTracking } from "./index";

const sendPageView = (location) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "pageview",
    page: {
      path: location.pathname,
      search: location.search,
      title: document.title,
    },
  });
};

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    sendPageView(location);
  }, [location]);

  return null;
};

export default function App() {
  const { auth } = useAuth();
  const { upToDate } = useFrontVersion();
  usePageTracking();

  return (
    <div className="App">
      <RouteChangeTracker />
      <Routes>
        {/* Public routes */}
        <Route element={<PersistLogin />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/reset-password" element={<ForgottenPassword />} />
        <Route
          path="/init-password/:token"
          element={<ForgottenPasswordReconf />}
        />
        <Route
          path="/init-password-invite/:token"
          element={<ForgottenPasswordReconf isInvite />}
        />
        <Route path="/signup/verify" element={<SignupVerifyPage />} />

        {/* Private routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth adminPage />}>
            <Route path="/admin" element={<AdminPage />} />
          </Route>
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Navigate to={auth.home} />} />
            <Route path="/mon-compte" element={<MonComptePage />} />

            {/* ===================== */}
            {/* Gestion des bailleurs */}
            {/* ===================== */}
            <Route path="/bailleur" element={<DashboardPage />} />
            <Route path="/pro" element={<DashboardPage />} />
            <Route
              path="/bailleur/stripe/return"
              element={<CheckoutReturn />}
            />
            <Route
              path="/bailleur/profiles"
              element={<ProfilesBailleurRootPage />}
            >
              <Route path="add" element={<ProfilesNomAddPage />} />
              <Route path=":profileNomId" element={<ProfilesNomEditPage />} />
              <Route
                path=":profileNomId/add"
                element={<ProfileAddPage isBailleur />}
              />
              <Route
                path={":profileNomId/:profileId"}
                element={<ProfileEditPage isProfile isBailleur />}
              />
            </Route>

            <Route path="/bailleur/properties" element={<PropertyRootPage />}>
              <Route
                path="folder/:propertyFolderId"
                element={<PropertyFolderShowPage />}
              >
                <Route path="edit" element={<PropertyEditPage />} />
              </Route>
              <Route path=":propertyId" element={<PropertyShowPage />}>
                <Route path="edit" element={<PropertyEditPage />} />
              </Route>
              <Route path="" element={<PropertyListPage />}>
                <Route path="add" element={<PropertyAddPage />} />
                <Route
                  path="draft/:id_draft"
                  element={<PropertyEditPage isDraft />}
                />
              </Route>
            </Route>

            <Route path="/bailleur/tenants" element={<TenantsRootPage />}>
              <Route path="" element={<TenantListPage />}>
                <Route
                  path={"add"}
                  element={<TenantAddPage type={TenantAddFormType.LOCATAIRE} />}
                />
              </Route>
              <Route path=":tenantId" element={<TenantShowPage />}>
                <Route
                  path=":profileId/add_garant"
                  element={<TenantAddGarantPage />}
                />

                <Route path=":profileId/edit" element={<TenantEditPage />} />
              </Route>
            </Route>
            <Route path="/bailleur/candidats" element={<TenantsRootPage />}>
              <Route path="" element={<CandidatListPage />}>
                <Route
                  path={"add"}
                  element={<TenantAddPage type={TenantAddFormType.CANDIDAT} />}
                />
              </Route>
              <Route path=":tenantId" element={<TenantShowPage />}>
                <Route
                  path=":profileId/add_garant"
                  element={<TenantAddGarantPage />}
                />

                <Route path=":profileId/edit" element={<TenantEditPage />} />
              </Route>
            </Route>

            <Route path="bailleur/finances/*" element={<FinancesRootPage />}>
              <Route path="edit/:bankId" element={<BankForm edit={true} />} />
              <Route path="add" element={<BankAdd />} />
              <Route path="post-add" element={<BankSyncForm />} />
              <Route path="post-sync" element={<BankSyncTransactions />} />
            </Route>

            {/* ====================== */}
            {/* Gestion des locataires */}
            {/* ====================== */}
            <Route path="/locataire" element={<LocataireRootPage />}>
              <Route path="" element={<LocataireListPage />} />
              <Route
                path="candidatures"
                element={<LocataireListCandidaturesPages />}
              />
              <Route path=":tenantId" element={<LocataireShowPage />} />
            </Route>

            {/* ===================== */}
            {/* Gestion des candidats */}
            {/* ===================== */}
            <Route path="/candidat" element={<CandidatRootPage />}>
              <Route path="" element={<CandidatShowPage />}>
                <Route
                  path="add"
                  element={<ProfileAddPage isCandidat withMandatoryFields />}
                />
                <Route
                  path={":profileId"}
                  element={<ProfileEditPage isProfile withMandatoryFields />}
                />
                <Route
                  path={":profileId/add_garant"}
                  element={
                    <ProfileAddPage isGarant isCandidat withMandatoryFields />
                  }
                />
                <Route
                  path="draft/:draftId"
                  element={<ProfileEditPage isDraft withMandatoryFields />}
                />
              </Route>
            </Route>
          </Route>
        </Route>

        {/* Catch all */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      {!upToDate && (
        <footer className={"version-footer"}>
          Une nouvelle version de l'application est disponible,{" "}
          <Button buttonStyle={"link"} onClick={() => window.location.reload()}>
            rafraîchissez la page
          </Button>{" "}
          pour une expérience optimale.
        </footer>
      )}
    </div>
  );
}
