import Select from "../../UI/Select";
import Card from "../../UI/Card";
import { Delete, Interrogation } from "../../UI/Icons";
import { TenantStatus } from "../../../models/tenant";
import moment from "moment";
import { TextOneOrMany } from "../../../utils";
import RadioStandalone from "../../UI/RadioStandalone";
import { Fragment } from "react";
import Tooltip from "../../UI/Tooltip";
import styles from "./QuittanceSelectTenant.module.css";
import { upperCaseFirstLetter } from "../../../models/utils";
import useTenants from "../../../hooks/use-tenants";
import Spinner from "../../UI/Spinner";

export default function QuittanceSelectTenant({
  tenantQuittanceStatuses,
  onSelect,
  onDelete,
  onChangeQuittanceType,
  isLoading,
}) {
  const { tenants } = useTenants();

  function isAJour(quittanceStatus) {
    return Object.values(quittanceStatus).reduce(
      (a, b) => a && b.up_to_date,
      true,
    );
  }

  return (
    <>
      {onSelect && (
        <Select
          name="id_tenant"
          onChange={(e) => {
            onSelect(parseInt(e.target.value));
            e.target.value = "";
          }}
        >
          <option value="">Selectionner un locataire</option>
          {tenants
            ?.filter((tenant) => tenant.status === TenantStatus.Actif.status)
            .map((option) => (
              <option key={option.id} value={option.id}>
                {option.profile_nom?.nom_profile}
              </option>
            ))}
          <option disabled>---Anciens locataires---</option>
          {tenants
            ?.filter((tenant) => tenant.status === TenantStatus.Archive.status)
            .map((option) => (
              <option key={option.id} value={option.id}>
                {option.profile_nom?.nom_profile}
              </option>
            ))}
        </Select>
      )}
      {Object.entries(tenantQuittanceStatuses).map(
        ([tenantId, tenantQuittanceStatus]) => {
          const tenant = tenants.find((t) => t.id === parseInt(tenantId));
          return (
            <Card
              key={tenantId}
              className={"padding10 marginB5 align-items-center"}
              type={!isLoading && !isAJour(tenantQuittanceStatus) && "error"}
            >
              {isLoading && <Spinner />}
              {!isLoading && (
                <>
                  <div className={"flex space-between align-items-center"}>
                    <div className={"primaryText"}>
                      {tenant?.profile_nom.nom_profile}
                    </div>
                    {onDelete && (
                      <div
                        onClick={() => onDelete(tenantId)}
                        className={"cursorPointer"}
                      >
                        <Delete />
                      </div>
                    )}
                  </div>
                  {!isAJour(tenantQuittanceStatus) && (
                    <div>
                      Le loyer ne semble pas à jour pour{" "}
                      <TextOneOrMany
                        one={" le mois suivant : "}
                        many={" les mois suivants : "}
                        number={Object.values(tenantQuittanceStatus).reduce(
                          (a, b) => (b.up_to_date ? a : a + 1),
                          0,
                        )}
                      />
                      <Tooltip
                        content={
                          <p>
                            Envoyer une quittance de loyer alors que le
                            locataire n'est pas à jour de son loyer peut vous
                            engager. <br />
                            Une quittance de loyer n'est faite que si le loyer a
                            été payé en entier. <br />
                            Si ce n'est pas le cas, l'usage est d'envoyer un
                            reçu.
                          </p>
                        }
                      >
                        <Interrogation />
                      </Tooltip>
                      <div className={styles.quittanceTypeForm}>
                        {Object.entries(tenantQuittanceStatus)
                          .filter(([_, status]) => !status.up_to_date)
                          .map(([month, status]) => (
                            <Fragment key={month}>
                              <div className={"marginL20"}>
                                {upperCaseFirstLetter(
                                  moment(month, "MM/YYYY").format("MMMM"),
                                )}
                              </div>
                              {[
                                { value: "recu", label: "Reçu" },
                                { value: "quittance", label: "Quittance" },
                                {
                                  value: "none",
                                  label: "Ne rien faire",
                                },
                              ].map((type) => (
                                <div key={type.value}>
                                  <RadioStandalone
                                    name={`${month}${tenantId}Action`}
                                    checked={status.type === type.value}
                                    value={type.value}
                                    label={type.label}
                                    onChange={(e) =>
                                      onChangeQuittanceType(tenantId, month, {
                                        type: e.target.value,
                                        up_to_date: false,
                                      })
                                    }
                                  />
                                </div>
                              ))}
                            </Fragment>
                          ))}
                      </div>
                    </div>
                  )}
                </>
              )}
            </Card>
          );
        },
      )}
    </>
  );
}
