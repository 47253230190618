import Card from "../UI/Card";
import DisplayField from "../UI/DisplayField";
import {
  Argent,
  CalendarCheck,
  Edit,
  Lock,
  MoneyCheckDollar,
  TypeBail,
} from "../UI/Icons";
import "moment/min/locales";
import styles from "./TenantLeaseCard.module.css";
import TenantLeaseForm from "./PartForms/TenantLeaseForm";
import PartForm from "../UI/PartForm";
import {
  TENANT_INITIAL_VALUE,
  tenantLeaseFormSchema,
} from "../../models/tenant";
import { sanitizeValues, toInitialValuesFromSchema } from "../../models/utils";
import { useModal } from "../../hooks/use-modal";
import useTenants from "../../hooks/use-tenants";
import moment from "moment/moment";
import { currencyFormatter } from "../../api/Functions";
import ButtonAction from "../UI/ButtonAction";

function TenantLeaseCard({ tenant, allowEdit }) {
  const [
    LeaseTenantFormModal,
    closeLeaseTenantFormModal,
    openLeaseTenantFormModal,
  ] = useModal();

  const { updateTenant } = useTenants();

  function getRecurrence(reccurrence) {
    if (reccurrence === 3) return "/trimestre";
    if (reccurrence === 12) return "/an";
    return "/mois";
  }

  return (
    <>
      <LeaseTenantFormModal size={"big"}>
        <PartForm
          partForm={<TenantLeaseForm />}
          initialValues={toInitialValuesFromSchema(
            tenantLeaseFormSchema,
            tenant,
            TENANT_INITIAL_VALUE,
          )}
          submitHandler={(values) =>
            updateTenant(tenant.id, sanitizeValues(values))
          }
          closeModal={closeLeaseTenantFormModal}
          validationSchema={tenantLeaseFormSchema}
        />
      </LeaseTenantFormModal>

      <Card className={"flex-grow"}>
        <div className={"flex space-between align-items-center marginB20"}>
          <p className={"cardTitle"}>Paramètres du bail</p>
          {allowEdit && (
            <div>
              <ButtonAction
                onClick={openLeaseTenantFormModal}
                logo={<Edit size={"20"} />}
                tooltip={"Editer les paramètres du bail"}
              />
            </div>
          )}
        </div>

        <div className={styles.content}>
          <DisplayField
            icon={<CalendarCheck stroke={"#9D9D9D"} />}
            fieldName="Début du bail"
            fieldValue={
              moment(tenant.debut).format("DD MMMM YYYY") || "Non renseigné"
            }
          />
          <DisplayField
            icon={<CalendarCheck stroke={"#9D9D9D"} />}
            fieldName={
              tenant.preavis
                ? "Date de fin (préavis déposé)"
                : "Date d'échéance du bail"
            }
            fieldValue={
              moment(tenant.fin).format("DD MMMM YYYY") || "Non renseigné"
            }
          />
          <DisplayField
            icon={<Argent />}
            fieldName={"Loyer hors charge"}
            fieldValue={
              tenant.loyer_hc
                ? `${currencyFormatter(tenant.loyer_hc)} 
                  ${getRecurrence(tenant.payment_recurrence)} 
                  ${tenant.tva ? " (TVA)" : ""}`
                : "Non renseigné"
            }
          />
          <DisplayField
            icon={<MoneyCheckDollar />}
            fieldName={
              tenant.type_charge === "provision"
                ? "Charges : provision sur charge"
                : "Charges : forfait de charge"
            }
            fieldValue={
              tenant.charges
                ? currencyFormatter(tenant.charges) +
                  getRecurrence(tenant.payment_recurrence)
                : "Non renseigné"
            }
          />
          {tenant.lease_type !== "mobilite" && (
            <DisplayField
              icon={<Lock />}
              fieldName={"Dépôt de garantie"}
              fieldValue={currencyFormatter(tenant.deposit) || "Non renseigné"}
            />
          )}
          <DisplayField
            icon={<TypeBail />}
            fieldName={"Type de bail"}
            fieldValue={"Bail " + tenant.lease_type || "Non renseigné"}
          />
          <DisplayField
            icon={<CalendarCheck stroke={"#9D9D9D"} />}
            fieldName={"Jour de paiement du loyer"}
            fieldValue={
              `Le ${tenant.day_payment} du mois ${
                tenant.payment_recurrence === 3 ? "(Trimestriel)" : ""
              } ${tenant.payment_recurrence === 12 ? "(Annuel)" : ""}` ||
              "Non renseigné"
            }
          />
          <DisplayField
            icon={<CalendarCheck stroke={"#9D9D9D"} />}
            fieldName={"Dernière révision de loyer"}
            fieldValue={
              tenant.last_revision_date
                ? moment(tenant.last_revision_date).format("DD MMMM YYYY")
                : "Non renseigné"
            }
          />
        </div>
      </Card>
    </>
  );
}

export default TenantLeaseCard;
