import Card from "../UI/Card";
import Button from "../UI/Button";
import ListFiles from "../UI/ListFiles";
import { useState } from "react";
import useLoader from "../../hooks/use-loader";

export default function ProfileRecapFiscal({
  recapFiscalHTML,
  onGenerateRecapFiscal,
}) {
  const [recapFile, setRecapFile] = useState(null);
  const [generatingRecap, generateRecap] = useLoader(onGenerateRecapFiscal);
  return (
    <>
      {recapFile && (
        <Card>
          <p className={"primaryText"}>Télécharger le document</p>
          <ListFiles files={[recapFile]} />
        </Card>
      )}
      <Card className={"marginT10"}>
        <div className={"flex space-between wrap gap10 align-items-center"}>
          <p className={"primaryText"}>Prévisualisation du document</p>
          <Button
            onClick={async () => {
              try {
                setRecapFile(await generateRecap());
              } catch (e) {
                console.error(e);
              }
            }}
            isLoading={generatingRecap}
          >
            Générer le PDF
          </Button>
        </div>
        <div dangerouslySetInnerHTML={{ __html: recapFiscalHTML }} />
      </Card>
    </>
  );
}
