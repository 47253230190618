import Menu from "../../components/Menu/Menu.js";
import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchBalanceTenants } from "../../api/Balance_tenants";
import { fetchPublicProperty } from "../../api/Properties";
import useAuth from "../../hooks/use-auth";
import useTenants from "../../hooks/use-tenants";

function LocataireRootPage() {
  const { tenants } = useTenants({ isLocataire: true });
  const [properties, setProperties] = useState([]);
  const [balances, setBalances] = useState([]);

  const { auth } = useAuth();

  useEffect(() => {
    (async () => {
      setBalances(await fetchBalanceTenants(auth.accessToken, true));
      const properties = [];
      for (const tenant of tenants) {
        properties.push(await fetchPublicProperty(tenant.id_bien));
      }
      setProperties(properties);
    })();
  }, [auth.accessToken, tenants]);

  const outletContext = {
    properties,
    balances,
  };

  return (
    <>
      <Menu isTenant />
      <Outlet context={outletContext} />
    </>
  );
}

export default LocataireRootPage;
