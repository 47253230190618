import {
  DOMAIN_TO_PARENT_ID_FIELD_NAME,
  DOMAINE_ID_SEPARATOR,
} from "./constants/balances";

export function formatBalanceAffectation(domaine, parentId) {
  return `${domaine}${DOMAINE_ID_SEPARATOR}${parentId}`;
}

export function getParentIdPropertyFromDomain(domaineBalance) {
  return DOMAIN_TO_PARENT_ID_FIELD_NAME[domaineBalance];
}

export function getBalanceParentId(balance) {
  return balance[getParentIdPropertyFromDomain(balance.domaine)];
}
