import Card from "../UI/Card";
import { TRANSACTION_CHILD_INITIAL_VALUE } from "../../models/transactionChild";
import { Croix, PlusBank } from "../UI/Icons";
import { FieldArray, useFormikContext } from "formik";
import style from "./TransactionSplitFieldArray.module.css";
import TextInput from "../UI/TextInput";
import BalanceTypeSelect from "../Balance/BalanceTypeSelect";
import BalanceAffectationSelect from "../Balance/BalanceAffectationSelect";

export default function TransactionSplitFieldArray({ name }) {
  const { values } = useFormikContext();

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          {values[name].map((child, index) => (
            <Card className={style.wrapper} padding={"md"} key={index}>
              <div
                className={style.close}
                onClick={() => arrayHelpers.remove(index)}
              >
                <Croix />
              </div>
              <TextInput
                type="text"
                name={`${name}[${index}].comment`}
                placeholder="Commentaire"
                label="Commentaire"
                marginForm={""}
                mandatory
              />
              <TextInput
                type="number"
                name={`${name}[${index}].montant`}
                placeholder="0"
                label="Montant"
                mandatory
                min={null}
                unit={"€"}
                step={"0.01"}
                size="sm"
                marginForm={""}
              />
              <BalanceAffectationSelect
                prefix={`${name}[${index}].`}
                mandatory
              />
              <BalanceTypeSelect prefix={`${name}[${index}].`} mandatory />
            </Card>
          ))}
          <Card
            className={`flex justify-content-center align-items-center paddingB10 paddingT10 marginB10`}
            onClick={() => arrayHelpers.push(TRANSACTION_CHILD_INITIAL_VALUE)}
          >
            <PlusBank />
            <p className={"secondaryText marginL10"}>Ajouter une entrée</p>
          </Card>
        </>
      )}
    />
  );
}
