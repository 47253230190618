import TextInput from "../../UI/TextInput";
import { Link } from "react-router-dom";
import AddressFields from "../../UI/AddressFields";
import { ReactSelect } from "../../UI/Select";
import Tooltip from "../../UI/Tooltip";
import { Interrogation } from "../../UI/Icons";

export default function HeaderPropertyForm({ profiles, isFolder }) {
  // On vérifie que le profileNom à bien des profiles de déclarés
  const selectOptions = profiles
    ?.filter((profile) => profile.profiles.length !== 0)
    .map((profile) => ({
      label: profile.nom_profile,
      value: profile.id,
    }));

  return (
    <>
      <TextInput
        type="text"
        name="nom"
        placeholder="Coloc Jeanne d'Arc"
        label={`Nom du ${isFolder ? "dossier" : "bien"}`}
        mandatory
      />

      <ReactSelect
        name={"id_profile_nom_owner"}
        label={"Profil bailleur à affecter"}
        placeholder="SCI Dupont"
        mandatory
        options={selectOptions}
      />

      <p className={"secondaryText marginB10 marginT5"}>
        Si votre profil n'apparaît pas, vous pouvez le créer dans la rubrique
        <Link to={"/bailleur/profiles"}> Profils bailleur</Link>
      </p>

      {!isFolder && <AddressFields nameRue={"rue"} mandatory />}
      {isFolder && (
        <TextInput
          type="number"
          name="surface_total"
          label={
            <>
              Surface totale{" "}
              <Tooltip
                content={
                  <p>
                    Utile pour les calculs de répartition des opérations
                    comptables au prorata de la surface. <br />
                    En particulier si le dossier représente un logement en
                    colocation individuelle, chaque chambre étant un bien dans
                    le dossier. <br />
                    Dans ce cas la surface à renseigner est la somme des
                    surfaces des chambres plus les parties communes.
                  </p>
                }
              >
                <Interrogation />
              </Tooltip>
            </>
          }
          placeholder="45"
          step={"0.01"}
          unit={"m²"}
          className={"flex-grow"}
        />
      )}
    </>
  );
}
