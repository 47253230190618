import {
  BalanceDomain,
  DOMAIN_TO_PARENT_ID_FIELD_NAME,
  DOMAINE_ID_SEPARATOR,
} from "../../utils/constants/balances";
import { TenantStatus } from "../../models/tenant";
import useTenants from "../../hooks/use-tenants";
import usePropertyFolders from "../../hooks/use-property-folders";
import useProperties from "../../hooks/use-properties";
import { getIn, useFormikContext } from "formik";
import {
  formatBalanceAffectation,
  getBalanceParentId,
  getParentIdPropertyFromDomain,
} from "../../utils/balances";
import { ReactSelect } from "../UI/Select";
import { stripEnd } from "../../utils";
import {
  buildPropertyFoldersTree,
  findPropertyFolder,
  getIncludedPropertyAndFolderIds,
} from "../../utils/properties";

function tenantToOption(tenant, property) {
  let label = tenant.profile_nom.nom_profile;
  if (property) {
    label += ` (${property.nom})`;
  }
  return {
    label: label,
    value: `${BalanceDomain.TENANT}${DOMAINE_ID_SEPARATOR}${tenant.id}`,
  };
}

function propOrFolderToOption(propOrFolder, domaine) {
  return {
    label: propOrFolder.nom,
    value: `${domaine}${DOMAINE_ID_SEPARATOR}${propOrFolder.id}`,
  };
}

function getAffectationValue(balance) {
  return formatBalanceAffectation(balance.domaine, getBalanceParentId(balance));
}

export default function BalanceAffectationSelect({
  prefix = "",
  parentId,
  domaineBalance,
  className,
  disabled,
  mandatory,
  label = "Affectation",
}) {
  const { values, setFieldValue } = useFormikContext();
  const { tenants } = useTenants();
  const { propertyFolders } = usePropertyFolders();
  const { properties, propertiesById } = useProperties({ withById: true });
  const name = `${prefix}affectation`;
  const balance = getIn(values, stripEnd(prefix, "."));

  let propFolderOptions = [];
  let propOptions = [];
  let activeTenantOptions = [];
  let archivedTenantOptions = [];
  if (!domaineBalance) {
    propFolderOptions = propertyFolders.map((folder) =>
      propOrFolderToOption(folder, BalanceDomain.FOLDER),
    );
    propOptions = properties.map((prop) =>
      propOrFolderToOption(prop, BalanceDomain.PROPERTY),
    );
    activeTenantOptions = tenants
      .filter((tenant) => tenant.status === TenantStatus.Actif.status)
      .map((tenant) => tenantToOption(tenant, propertiesById[tenant.id_bien]));

    archivedTenantOptions = tenants
      .filter((tenant) => tenant.status === TenantStatus.Archive.status)
      .map((tenant) => tenantToOption(tenant, propertiesById[tenant.id_bien]));
  } else if (domaineBalance === BalanceDomain.FOLDER) {
    const propertyFoldersTree = buildPropertyFoldersTree(
      propertyFolders,
      properties,
    );
    const { propertyIds, folderIds } = getIncludedPropertyAndFolderIds(
      findPropertyFolder(propertyFoldersTree.property_folders, parentId),
    );
    propFolderOptions = propertyFolders
      .filter((pf) => folderIds.has(pf.id) && pf.id !== parentId)
      .map((folder) => propOrFolderToOption(folder, BalanceDomain.FOLDER));
    propOptions = properties
      .filter((p) => propertyIds.has(p.id))
      .map((prop) => propOrFolderToOption(prop, BalanceDomain.PROPERTY));
  }

  const affectationSelectOptions = [
    { label: "Dossiers", options: propFolderOptions },
    { label: "Biens", options: propOptions },
    { label: "Locataires Actifs", options: activeTenantOptions },
    { label: "Locataires Archivés", options: archivedTenantOptions },
  ];

  function affectationChangeHandler(prefix, option, domaineBalance) {
    if (option == null) return;

    const promises = [];
    const [newDomaineBalance, parentId] =
      option.value.split(DOMAINE_ID_SEPARATOR);
    if (newDomaineBalance !== domaineBalance) {
      promises.push(
        setFieldValue(`${prefix}type`, ""),
        setFieldValue(`${prefix}domaine`, newDomaineBalance),
      );
    }
    // Reset all parent IDs and set the new one
    for (let domain in DOMAIN_TO_PARENT_ID_FIELD_NAME) {
      promises.push(
        setFieldValue(`${prefix}${DOMAIN_TO_PARENT_ID_FIELD_NAME[domain]}`, ""),
      );
    }
    promises.push(
      setFieldValue(
        `${prefix}${getParentIdPropertyFromDomain(newDomaineBalance)}`,
        parentId,
      ),
    );
    return Promise.all(promises);
  }

  return (
    <ReactSelect
      label={label}
      name={name}
      placeholder="Chambre 10 ou Jean Dupond"
      value={getAffectationValue(balance)}
      options={affectationSelectOptions}
      onChange={(option) =>
        affectationChangeHandler(prefix, option, balance.domaine)
      }
      isClearable={false}
      isDisabled={disabled}
      className={className}
      mandatory={mandatory}
    />
  );
}
