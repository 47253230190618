import { array, number, object, string } from "yup";
import { dateMax, stringMax } from "./utils";

export const balanceSchema = object({
  date_transac: dateMax().required(" "),
  montant: number().required(" "),
  type: string().required(" "),
  comment: stringMax(250).optional(),
  id_bien: number().optional(),
  id_tenant: number().optional(),
  id_property_folder: number().optional(),
  domaine: stringMax(250).optional(),
  files: array().of(object()),
});

export const balanceIV = {
  date_transac: "",
  montant: "",
  type: "",
  comment: "",
  id_bien: "",
  id_tenant: "",
  id_property_folder: "",
  domaine: "",
  files: [],
};
Object.freeze(balanceIV);

export const balanceMasseValidationSchema = object({
  balances: array().of(balanceSchema),
});

export const BALANCE_MASSE_INITIAL_VALUE = {
  balances: [{ ...balanceIV }],
};
