import ProfileNomCard from "./ProfileNomCard";
import useProfileNoms from "../../hooks/use-profile-noms";
import { PlusBank } from "../UI/Icons";
import ButtonCard from "../UI/ButtonCard";
import { useNavigate } from "react-router-dom";

function ProfileList() {
  const {
    profileNoms,
    shareProfileNom,
    deleteSharedProfileNom,
    generateRecapFiscal,
  } = useProfileNoms();
  const nav = useNavigate();

  return profileNoms.length === 0 ? (
    <ButtonCard
      className="flex align-items-center marginB10 marginT10 padding10"
      onClick={() => nav("add")}
    >
      <PlusBank />
      <span className="marginL5">Ajouter un profil bailleur</span>
    </ButtonCard>
  ) : (
    profileNoms.map((profileNom, i) => (
      <ProfileNomCard
        profileNom={profileNom}
        onGenerateRecapFiscal={generateRecapFiscal}
        onShare={shareProfileNom}
        onShareDelete={deleteSharedProfileNom}
        key={i}
      />
    ))
  );
}

export default ProfileList;
