import TextInput from "../UI/TextInput";
import FormUpload from "../File/FormUpload";
import Select from "../UI/Select";
import { AncienneteStatus, StatusPro } from "../../models/profile";
import { useFormikContext } from "formik";

export default function ProfileFormPro() {
  const { values } = useFormikContext();
  return (
    <>
      <h3>Activité professionnelle</h3>

      <div>
        <TextInput
          type="text"
          name="activite"
          placeholder="Menuisier"
          label="Nom l'activité professionnelle"
        />
        <Select label="Situation" name="status_pro">
          <option value="">Selectionnez une situation</option>
          <option value={StatusPro.ETUDIANT}>Etudiant</option>
          <option value={StatusPro.CDI}>CDI</option>
          <option value={StatusPro.CDD}>CDD</option>
          <option value={StatusPro.INTERIM}>Interimaire</option>
          <option value={StatusPro.FONCTIONNAIRE}>Fonctionnaire</option>
          <option value={StatusPro.INDEPENDANT}>Travailleur indépendant</option>
          <option value={StatusPro.RETRAITE}>Retraité</option>
          <option value={StatusPro.SANSEMPLOI}>Sans emploi</option>
        </Select>
        {(values.status_pro !== StatusPro.SANSEMPLOI ||
          values.status_pro !== StatusPro.RETRAITE ||
          values.status_pro !== StatusPro.ETUDIANT) && (
          <Select label="Ancienneté" name="anciennete">
            <option value={""}>Ancienneté de la situation</option>
            <option value={AncienneteStatus.MONTH3}>Moins de 3 mois</option>
            <option value={AncienneteStatus.MONTH6}>Entre 3 et 6 mois</option>
            <option value={AncienneteStatus.YEAR1}>Entre 6 mois et 1 an</option>
            <option value={AncienneteStatus.YEAR2}>Entre 1 an et 2 ans</option>
            <option value={AncienneteStatus.YEAR2PLUS}>Plus de 2 ans</option>
          </Select>
        )}
        <TextInput
          type="number"
          step="0.01"
          name="revenus_brut"
          placeholder="Revenus mensuels NET"
          label="Revenus mensuels NET"
          unit={"€"}
        />
        Justificatif de la situation
        <FormUpload tag="pro" />
      </div>
    </>
  );
}
