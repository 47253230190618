import useSearchFiles from "../../hooks/use-search-files";
import Card from "../UI/Card";
import { Selectable } from "../UI/Selectable";
import useTenants from "../../hooks/use-tenants";
import { useState } from "react";
import TextInputStandalone from "../UI/TextInputStandalone";
import moment from "moment";
import Button from "../UI/Button";
import { DocType } from "../../utils/constants/documents";
import useLoader from "../../hooks/use-loader";
import DocumentDownloader from "../UI/DocumentDownloader";
import useProperties from "../../hooks/use-properties";
import DocumentDownloadLink from "../UI/DocumentDownloadLink";
import styles from "./ProfileDocuments.module.css";
import { House, Oeil } from "../UI/Icons";
import useMediaQuery from "../../hooks/use-media-query";

export default function ProfileDocuments() {
  const initialFrom = moment().startOf("year").format("YYYY-MM-DD");
  const initialTo = moment().format("YYYY-MM-DD");
  const { tenantsById } = useTenants({ withById: true });
  const { propertiesById } = useProperties({ withById: true });

  const [dateFrom, setDateFrom] = useState(initialFrom);
  const [dateTo, setDateTo] = useState(initialTo);
  const { files, isLoading, generateZip } = useSearchFiles({
    date_from: dateFrom,
    date_to: dateTo,
    type: DocType.QUITTANCE,
  });
  const [isZipping, zip] = useLoader(generateZip);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  async function generateSignedUrl(file_ids, setSignedUrl) {
    try {
      const response = await zip(file_ids);
      setSignedUrl(response.data);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Card>
      <Selectable items={files}>
        <div className={"flex wrap gap10 space-between marginB20"}>
          <div className={"flex wrap gap10 align-items-center"}>
            <label>
              <Selectable.Checkbox selectAll /> Tout sélectionner
            </label>
            <TextInputStandalone
              onChange={(e) => setDateFrom(e.target.value)}
              label={"Depuis"}
              type={"date"}
              value={dateFrom}
            />
            <TextInputStandalone
              onChange={(e) => setDateTo(e.target.value)}
              label={"Jusqu'à"}
              type={"date"}
              value={dateTo}
            />
          </div>
          <div>
            <Button
              className={"marginB5"}
              onClick={() => {
                setDateFrom(initialFrom);
                setDateTo(initialTo);
              }}
            >
              Reset
            </Button>
            <DocumentDownloader>
              {(setSignedUrl) => (
                <Selectable.ActionButton
                  disabled={isLoading}
                  isLoading={isZipping}
                  resetOnClick
                  onClick={(file_ids) =>
                    generateSignedUrl(file_ids, setSignedUrl)
                  }
                >
                  Télécharger
                </Selectable.ActionButton>
              )}
            </DocumentDownloader>
          </div>
        </div>
        {files.map((file, i) => (
          <Card key={file.id} padding={"md"} className={"marginB5"}>
            <label className={styles.grid}>
              <div>
                <Selectable.Checkbox value={file.id} index={i} />
                <span className={"primaryText marginL5"}>
                  {tenantsById[file.id_tenant]?.profile_nom.nom_profile ||
                    "Inconnu"}{" "}
                </span>
              </div>
              {!isSmallScreen && (
                <div className={"flex gap10 align-items-center"}>
                  <House />
                  {propertiesById[tenantsById[file.id_tenant]?.id_bien]?.nom ||
                    "Inconnu"}{" "}
                </div>
              )}
              <div>
                {moment(file.meta["date_quittance"]).format("MMMM YYYY")}
              </div>
              <div>
                <DocumentDownloadLink documentId={file.id} file={file}>
                  <Oeil stroke={"#0C69F5"} />
                </DocumentDownloadLink>
              </div>
            </label>
          </Card>
        ))}
      </Selectable>
    </Card>
  );
}
