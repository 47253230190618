import { array, number, object } from "yup";
import { stringMax } from "./utils";

export const transactionChildValidationSchema = object({
  comment: stringMax(250).required(" "),
  montant: number().required(" "),
  id_bien: number().optional(),
  id_tenant: number().optional(),
  id_property_folder: number().optional(),
  parent: stringMax(250).optional(),
  type: stringMax(250).required(" "),
});

export const transactionSplitValidationSchema = object({
  balances: array().of(transactionChildValidationSchema),
});

export const TRANSACTION_CHILD_INITIAL_VALUE = {
  comment: "",
  montant: "",
  id_bien: "",
  id_tenant: "",
  id_property_folder: "",
  parent: "",
  type: "",
};

export const TRANSACTION_SPLIT_FORM_INITIAL_VALUE = {
  balances: [TRANSACTION_CHILD_INITIAL_VALUE],
  template: "",
  update_template: false,
};
