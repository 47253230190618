export function get_profile_display_name(profile) {
  if (!profile) {
    return "";
  }
  if (profile.type === 1) {
    return `${profile.prenom} ${profile.nom}`;
  } else {
    return profile.nom_societe;
  }
}
