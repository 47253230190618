import useTenants from "../../hooks/use-tenants";
import Card from "../UI/Card";
import ButtonCard from "../UI/ButtonCard";
import { PlusBank } from "../UI/Icons";
import { TextOneOrMany } from "../../utils";
import ProfileDisplaySmallCard from "../Profile/ProfileDisplaySmallCard";
import { ProfileStatus } from "../../models/profile";
import { get_profile_display_name } from "../../utils/profiles";
import { ReactSelectStandalone } from "../UI/SelectStandalone";

export default function TenantAddProfile({
  profileToPush,
  label,
  onAdd,
  onDelete,
  openAddProfileModal,
}) {
  const { tenants } = useTenants();
  let profilesIndiv = [];
  tenants?.forEach((tenant) =>
    tenant.profile_nom.profiles
      .filter((p) => p.status === ProfileStatus.Actif.status)
      .forEach((profile) =>
        profilesIndiv.push({
          profile: profile,
          tenant: {
            status: tenant.status,
            id_bien: tenant.id_bien,
            id: tenant.id,
          },
        }),
      ),
  );
  profilesIndiv.sort((a, b) => {
    const nameA = get_profile_display_name(a.profile);
    const nameB = get_profile_display_name(b.profile);
    return nameA.localeCompare(nameB);
  });

  const profilesNonGarantOptions = profilesIndiv
    .filter((prof) => prof.profile.garantor_of === null)
    .map((option) => ({
      value: option.profile.id,
      label: get_profile_display_name(option.profile),
    }));
  const profilesGarantOptions = profilesIndiv
    .filter((prof) => prof.profile.garantor_of !== null)
    .map((option) => ({
      value: option.profile.id,
      label: get_profile_display_name(option.profile),
    }));

  const profilesOptions = [
    { label: "Profils", options: profilesNonGarantOptions },
    { label: "Garants", options: profilesGarantOptions },
  ];

  function handleAddFromList(option) {
    const prof = profilesIndiv.find(
      (profile) => profile.profile.id === option.value,
    );
    if (
      profileToPush.findIndex(
        (profile) => profile.profile.id === prof.profile.id,
      ) === -1
    ) {
      // Only add if not already in list
      onAdd(prof);
    }
  }
  return (
    <>
      <Card className={"marginB10"}>
        <p className={"secondaryText text-center"}>{label}</p>
        <ButtonCard
          className={
            "padding5 flex align-items-center marginB10 marginT20 w-100"
          }
          onClick={openAddProfileModal}
        >
          <PlusBank />
          <span className={"marginL10"}>Créer un nouveau profil locataire</span>
        </ButtonCard>
        <ReactSelectStandalone
          value={null}
          onChange={handleAddFromList}
          options={profilesOptions}
          label="Sélectionner un profil existant"
          placeholder="Martin DUPONT"
        />
        {profileToPush.length > 0 && (
          <p className={"primaryText marginT10"}>
            <TextOneOrMany
              one={"Locataire sélectionné"}
              many={"Liste des locataires sélectionnés"}
              number={profileToPush.length}
            />
          </p>
        )}
        {profileToPush.map((profile, index) => (
          <ProfileDisplaySmallCard
            profile={profile.profile}
            onDelete={() => onDelete(index)}
            key={index}
          />
        ))}
      </Card>
    </>
  );
}
