import ToggleSwitch from "../UI/ToggleSwitch";
import moment from "moment";
import CardToggle from "../UI/CardToggle";
import { Edit } from "../UI/Icons";
import ButtonAction from "../UI/ButtonAction";
import { useModal } from "../../hooks/use-modal";
import ActionForm from "./ActionForm";
import { useState } from "react";
import { AutoActionType } from "../../utils/constants/autoActions";

export default function Action({
  action,
  checked,
  onToggle,
  onUpdate,
  tenantAutoAction,
  disabled,
}) {
  const [Modal, closeModal, openModal] = useModal();
  const [autoAction, setAutoAction] = useState(null);

  async function updateAutoActionHandler(values) {
    await onUpdate(autoAction.id, values);
    closeModal();
  }

  return (
    <>
      <ActionForm
        ModalElement={Modal}
        onSubmit={updateAutoActionHandler}
        action={autoAction}
      />
      <CardToggle
        padding={"md"}
        arrowBefore
        title={
          <>
            <div className={"marginRAuto"}>{action.nom}</div>
            <ToggleSwitch
              status={checked}
              onToggle={(e) => onToggle(e, action, tenantAutoAction)}
              disabled={disabled}
            />
          </>
        }
        innerFlat
      >
        <p className={"marginT10 primaryText"}>
          Prochaine occurance de cette action :{" "}
          {!tenantAutoAction && "non activé"}
          {tenantAutoAction &&
            (tenantAutoAction.action_date
              ? moment(tenantAutoAction.action_date).format("DD/MM/YYYY")
              : "non prédéfini")}
          {action.static_id === AutoActionType.ATTESTATION_CHAUFFAGE && (
            <ButtonAction
              onClick={() => {
                openModal();
                setAutoAction(tenantAutoAction);
              }}
              logo={<Edit size={"20"} />}
              tooltip={"Modifier la date d'exécution"}
              className={"marginL10"}
            />
          )}
        </p>
        <div
          className={"marginT10"}
          dangerouslySetInnerHTML={{ __html: action.commentaires }}
        />
      </CardToggle>
    </>
  );
}
