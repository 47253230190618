import { Form, Formik } from "formik";
import SendRecommande from "./SendRecommande";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import Card from "../UI/Card";
import { useState } from "react";
import { handleAPIError } from "../../utils";

export default function SendRecommandeForm({ tenant, file, onSend }) {
  const [sendRecommandeError, setSendRecommandeError] = useState("");

  async function sendRecommanderHandler(values) {
    try {
      setSendRecommandeError("");
      await onSend(file.id, values);
    } catch (error) {
      setSendRecommandeError(handleAPIError(error));
    }
  }

  const initialValues = {
    id_expediteur: "",
    id_destinataire: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={sendRecommanderHandler}
      enableReinitialize
    >
      <Form>
        <SendRecommande tenant={tenant} file={file} />
        <div className={"text-right"}>
          <FormikSubmitButton>
            Envoyer une lettre recommandée
          </FormikSubmitButton>
        </div>
        {sendRecommandeError && (
          <Card type={"error"} className={"padding10 text-center marginT10"}>
            {sendRecommandeError}
          </Card>
        )}
      </Form>
    </Formik>
  );
}
