import useCourriers from "../../../hooks/use-courriers";
import { Fragment } from "react";
import CardCourrier from "../../Courrier/CardCourrier";
import { FileIcon } from "../../UI/Icons";
import IconAndText from "../../UI/IconAndText";

export default function SelectCourrier({ type, onSelectCourrier }) {
  const { courriers } = useCourriers(type);
  const uniqueCategories = [
    ...new Set(courriers.map((courrier) => courrier.category)),
  ];

  return (
    <>
      {uniqueCategories.map((category) => {
        let text = "";
        switch (category) {
          case "information":
            text = "Vous souhaitez envoyer une information ?";
            break;
          case "impaye":
            text = "Vous faites face à un impayé ?";
            break;
          case "trouble":
            text = "Vous faites face à des troubles de voisinage ?";
            break;
          case "justificatif":
            text = "Vous souhaitez demander un justificatif ?";
            break;
          case "meuble":
            text = "S'il s'agit d'un bail meublé";
            break;
          case "nu":
            text = "S'il s'agit d'un bail nu";
            break;
          case "montant":
            text =
              "Vous souhaitez modifier le montant du loyer ou des charges ?";
            break;
          default:
            text = "Autres documents";
        }
        return (
          <Fragment key={category}>
            <IconAndText
              icon={<FileIcon />}
              text={<p className={"primaryText"}>{text}</p>}
              className={"marginT10"}
            />
            <div className={"w-100 border-blue marginT5 marginB10"}></div>
            <CardCourrier
              onSelectCourrier={onSelectCourrier}
              courriers={courriers.filter(
                (courrier) => courrier.category === category,
              )}
            />
          </Fragment>
        );
      })}
    </>
  );
}
