import TextInput from "../UI/TextInput";
import BalanceTypeSelect from "./BalanceTypeSelect";
import FileInput from "../UI/FileInput";
import { DocType } from "../../utils/constants/documents";
import RadioStandalone from "../UI/RadioStandalone";
import RadioGroup from "../UI/RadioGroup";
import { useEffect, useMemo, useState } from "react";
import BalanceAffectationSelect from "./BalanceAffectationSelect";
import { FieldArray, getIn, useFormikContext } from "formik";
import { balanceIV } from "../../models/balanceTenant";
import { Croix, PlusBank } from "../UI/Icons";
import ButtonCard from "../UI/ButtonCard";
import Button from "../UI/Button";
import usePropertyFolders from "../../hooks/use-property-folders";
import useProperties from "../../hooks/use-properties";
import {
  buildPropertyFoldersTree,
  findPropertyFolder,
} from "../../utils/properties";
import {
  BalanceDomain,
  DOMAINE_ID_SEPARATOR,
} from "../../utils/constants/balances";
import {
  formatBalanceAffectation,
  getBalanceParentId,
} from "../../utils/balances";
import Card from "../UI/Card";
import { currencyFormatter } from "../../api/Functions";

const DISABLED_FIELD_HINT =
  "La valeur n'est pas modifiable car associée à une transaction de votre compte bancaire.";

class OperationType {
  static SIMPLE = "simple";
  static SPLIT = "split";
}

class RepartitionType {
  static PRORATA_SURFACE = "prorata_surface";
  static UNIFORM = "uniform";
  static MANUAL = "manual";
}

export default function BalanceFields({
  isFromTransac,
  balanceId,
  parentId,
  domaineBalance,
  prefix = "",
  withJustificatif = false,
  withSplitChoice = false,
  withAffectation = false,
}) {
  const [operationType, setOperationType] = useState(OperationType.SIMPLE);
  const [repartitionType, setRepartitionType] = useState(
    RepartitionType.PRORATA_SURFACE,
  );
  const [repartitionWarning, setRepartitionWarning] = useState("");
  useEffect(() => {
    if (!withSplitChoice) {
      setOperationType(OperationType.SIMPLE);
    }
  }, [withSplitChoice]);
  const { values, setFieldValue, handleChange } = useFormikContext();
  const { propertyFolders, propertyFoldersById } = usePropertyFolders({
    withById: true,
  });
  const { properties, propertiesById } = useProperties({ withById: true });
  const folderTree = useMemo(
    () => buildPropertyFoldersTree(propertyFolders, properties),
    [properties, propertyFolders],
  );
  const parentFolder = useMemo(
    () => findPropertyFolder(folderTree.property_folders, parentId),
    [folderTree, parentId],
  );

  const splitInputsName = `${prefix}balances`;

  async function operationTypeChangeHandler(operationType) {
    setOperationType(operationType);
    if (operationType === OperationType.SIMPLE) {
      await setFieldValue(splitInputsName, undefined);
    }
    if (operationType === OperationType.SPLIT) {
      const splitInitialValues = [];
      for (let prop of parentFolder.properties) {
        splitInitialValues.push({
          ...balanceIV,
          id_bien: prop.id,
          domaine: BalanceDomain.PROPERTY,
        });
      }
      for (let folder of parentFolder.property_folders) {
        splitInitialValues.push({
          ...balanceIV,
          id_property_folder: folder.id,
          domaine: BalanceDomain.FOLDER,
        });
      }
      await setFieldValue(splitInputsName, splitInitialValues);
    }
  }

  const totalAmountCents = Math.trunc(values.montant * 100) || 0;
  const balanceAffectationsCSV = getIn(values, splitInputsName, [])
    .map((balance) =>
      formatBalanceAffectation(balance.domaine, getBalanceParentId(balance)),
    )
    .join();

  useEffect(() => {
    if (operationType === OperationType.SIMPLE) return;
    let amountsCents = [];
    const affectations = balanceAffectationsCSV.split(",");
    const selectedPropertiesAndFolders = [];
    affectations.forEach((affectation) => {
      const [domaine, parentId] = affectation.split(DOMAINE_ID_SEPARATOR);
      let collection;
      if (domaine === BalanceDomain.PROPERTY) {
        collection = propertiesById;
      } else if (domaine === BalanceDomain.FOLDER) {
        collection = propertyFoldersById;
      }

      if (collection) {
        selectedPropertiesAndFolders.push([collection[parentId], domaine]);
      }
    });

    if (repartitionType === RepartitionType.PRORATA_SURFACE) {
      const totalSurface = selectedPropertiesAndFolders.reduce(
        (acc, [propOrFolder]) => acc + (propOrFolder.surface_total ?? 0),
        0,
      );

      amountsCents = selectedPropertiesAndFolders.map(([propOrFolder], i) =>
        Math.trunc(
          ((propOrFolder.surface_total ?? 0) / totalSurface) * totalAmountCents,
        ),
      );
    } else if (repartitionType === RepartitionType.UNIFORM) {
      const propertiesAndFoldersCount = selectedPropertiesAndFolders.length;
      amountsCents = Array(propertiesAndFoldersCount).fill(
        Math.trunc(totalAmountCents / propertiesAndFoldersCount),
      );
    }

    let cumulAmount = amountsCents.reduce((acc, amount) => acc + amount, 0);
    if (repartitionType !== RepartitionType.MANUAL) {
      // Ensure the sum of amounts is equal to the total amount and distribute potential rounding error on amounts
      if (cumulAmount !== totalAmountCents) {
        let i = 0;
        while (totalAmountCents - cumulAmount > 0) {
          amountsCents[i++] += 1;
          cumulAmount++;
        }
      }
    }

    Promise.all(
      amountsCents.map((amountCents, i) =>
        setFieldValue(`${splitInputsName}.${i}.montant`, amountCents / 100),
      ),
    ).catch((e) => console.error(e));
  }, [
    propertiesById,
    propertyFoldersById,
    repartitionType,
    balanceAffectationsCSV,
    totalAmountCents,
    operationType,
    setFieldValue,
    splitInputsName,
  ]);

  useEffect(() => {
    if (operationType === OperationType.SIMPLE) return;
    const totalSplitAmount = Math.round(
      getIn(values, splitInputsName, []).reduce(
        (acc, balance) => acc + (balance.montant || 0),
        0,
      ) * 100,
    );

    if (totalSplitAmount !== totalAmountCents) {
      setRepartitionWarning(
        `La somme des montant répartis (${currencyFormatter(
          totalSplitAmount / 100,
        )}) ne correspond pas au montant total (${currencyFormatter(
          totalAmountCents / 100,
        )}).`,
      );
    } else {
      setRepartitionWarning("");
    }
  }, [operationType, splitInputsName, totalAmountCents, values]);

  const splitInputs = (
    <>
      <FieldArray name={splitInputsName}>
        {({ push, remove }) => {
          return (
            <>
              <p className={"primaryText marginB10"}>Répartition</p>
              <RadioGroup layout={"row-wrap"}>
                <RadioStandalone
                  name={"repartition_type"}
                  label={"Prorata surface"}
                  checked={repartitionType === RepartitionType.PRORATA_SURFACE}
                  onChange={() =>
                    setRepartitionType(RepartitionType.PRORATA_SURFACE)
                  }
                />
                <RadioStandalone
                  name={"repartition_type"}
                  label={"Égale"}
                  checked={repartitionType === RepartitionType.UNIFORM}
                  onChange={() => setRepartitionType(RepartitionType.UNIFORM)}
                />
                <RadioStandalone
                  name={"repartition_type"}
                  label={"Manuelle"}
                  checked={repartitionType === RepartitionType.MANUAL}
                  onChange={() => setRepartitionType(RepartitionType.MANUAL)}
                />
              </RadioGroup>
              <div className={"flex flex-column gap10 marginB10"}>
                {getIn(values, splitInputsName, []).map(
                  (balanceValues, index) => {
                    const prefix = `${splitInputsName}.${index}.`;
                    return (
                      <div className={"flex gap10"} key={index}>
                        <div className="flex wrap gap10 flex-grow">
                          <BalanceAffectationSelect
                            prefix={prefix}
                            parentId={parentId}
                            domaineBalance={domaineBalance}
                            mandatory
                            className={"flex-grow"}
                            label=""
                          />
                          <TextInput
                            type="number"
                            step="0.01"
                            name={`${prefix}montant`}
                            placeholder="535,43"
                            size={"md"}
                            mandatory
                            min={null}
                            unit={"€"}
                            marginForm={""}
                            onChange={(e) => {
                              setRepartitionType(RepartitionType.MANUAL);
                              handleChange(e);
                            }}
                          />
                        </div>
                        <Button
                          onClick={() => remove(index)}
                          buttonStyle={"link"}
                        >
                          <Croix />
                        </Button>
                      </div>
                    );
                  },
                )}
              </div>
              <ButtonCard
                className={`flex justify-content-center align-items-center marginB10 w-100`}
                onClick={() => push({ ...balanceIV })}
              >
                <PlusBank />
                <p className={"secondaryText marginL10"}>Ajouter une entrée</p>
              </ButtonCard>
              {repartitionWarning && (
                <Card type={"warning"} className={"marginB10"} padding={"md"}>
                  <p>{repartitionWarning}</p>
                </Card>
              )}
            </>
          );
        }}
      </FieldArray>
    </>
  );

  const inputs = (
    <>
      <TextInput
        type="date"
        name={`${prefix}date_transac`}
        className={"marginB10"}
        disabled={isFromTransac}
        title={isFromTransac ? DISABLED_FIELD_HINT : undefined}
        label={"Date de l'opération"}
        mandatory
        marginForm={""}
      />
      {withAffectation && (
        <BalanceAffectationSelect
          className={"marginB10"}
          prefix={prefix}
          disabled={!withAffectation}
          mandatory
        />
      )}
      <BalanceTypeSelect
        prefix={prefix}
        className={"flex-grow marginB10"}
        mandatory
      />
      <TextInput
        type="number"
        step="0.01"
        name={`${prefix}montant`}
        placeholder="535,43"
        disabled={isFromTransac}
        title={isFromTransac ? DISABLED_FIELD_HINT : undefined}
        label={`Montant ${
          operationType === OperationType.SPLIT ? "total" : ""
        }`}
        mandatory
        min={null}
        unit={"€"}
        marginForm={""}
        className={"marginB10"}
      />
      <TextInput
        type="text"
        name={`${prefix}comment`}
        disabled={isFromTransac}
        title={isFromTransac ? DISABLED_FIELD_HINT : undefined}
        placeholder="Virement de M.DUPONT"
        label={"Commentaire"}
        marginForm={""}
        className={"marginB10"}
      />

      {operationType === OperationType.SPLIT && splitInputs}

      {withJustificatif && (
        <>
          Justificatifs
          <FileInput
            documentId={balanceId}
            doctype={DocType.JUSTIFICATIF_BALANCE}
            path={`${prefix}files`}
          />
        </>
      )}
    </>
  );

  return (
    <>
      {withSplitChoice && (
        <>
          <p>Type d'opération</p>
          <RadioGroup layout={"row-wrap"}>
            <RadioStandalone
              name={"operation_type"}
              label={"Opération simple"}
              checked={operationType === OperationType.SIMPLE}
              onChange={() => operationTypeChangeHandler(OperationType.SIMPLE)}
            />
            <RadioStandalone
              name={"operation_type"}
              label={"Opération à répartir"}
              checked={operationType === OperationType.SPLIT}
              onChange={() => operationTypeChangeHandler(OperationType.SPLIT)}
            />
          </RadioGroup>
        </>
      )}
      {inputs}
    </>
  );
}
