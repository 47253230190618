import useSWR from "swr";
import useAuthenticatedAxios from "./use-authenticated-axios";
import { useCallback, useMemo } from "react";

const ROOT_PATH = "documents";

export default function useSearchFiles(params) {
  const axios = useAuthenticatedAxios();
  const fallbackData = useMemo(() => [], []);

  const { data: files, ...rest } = useSWR(
    {
      url: `${ROOT_PATH}/search`,
      options: { params },
    },
    ({ url, options }) => {
      return axios.get(url, { params: options.params }).then((res) => res.data);
    },
    { fallbackData },
  );

  const generateZip = useCallback(
    async function (fileIds) {
      return await axios.post(`${ROOT_PATH}/zip`, {
        file_ids: fileIds,
      });
    },
    [axios],
  );
  return { files, generateZip, ...rest };
}
