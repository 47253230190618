import { useLocation, useNavigate } from "react-router-dom";
import { useNordigenAccounts } from "../../hooks/use-nordigen";
import { useModal } from "../../hooks/use-modal";
import Spinner from "../UI/Spinner";
import Card from "../UI/Card";
import { handleAPIError, UNKNOWN_ERROR_MESSAGE } from "../../utils";
import BankCheckbox from "./BankCheckbox";
import { Form, Formik } from "formik";
import { array, object, string } from "yup";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import { useState } from "react";

export default function BankSyncForm() {
  const searchParams = new URLSearchParams(useLocation().search);
  const referenceId = searchParams.get("reference_id");

  let { accounts, isLoading, error, synchronizeAccounts } =
    useNordigenAccounts(referenceId);

  const [Modal] = useModal(true);
  const nav = useNavigate();

  const [synchronizeError, setSynchronizeError] = useState(null);

  if (isLoading) {
    return (
      <Modal size={"medium"}>
        <h2 className={"marginB20"}>Chargement des comptes...</h2>
        <Spinner width={"5em"} />
      </Modal>
    );
  }

  if (error) {
    let errorMessage = UNKNOWN_ERROR_MESSAGE;
    if (error.response?.status === 404) {
      errorMessage =
        "Aucun compte n'a pu être récupéré. Veuillez recommencer la connexion à votre banque.";
    } else if (error.response?.status === 400) {
      errorMessage =
        "La requête est invalide. Veuillez recommencer la connexion à votre banque.";
    }
    return (
      <Modal size={"medium"}>
        <h2>Erreur</h2>
        <Card type={"error"}>{errorMessage}</Card>
      </Modal>
    );
  }

  async function submitHandler(values) {
    setSynchronizeError(null);
    try {
      await synchronizeAccounts(values.accounts);
      nav("../post-sync", {
        state: { accounts: values.accounts },
        replace: true,
      });
    } catch (e) {
      setSynchronizeError(handleAPIError(e));
    }
  }

  // Preselect accounts that have a status i.e. that are already synced with Qalimo
  const initialValues = {
    accounts: accounts
      .filter(
        (account) => account.status !== null && account.status !== undefined,
      )
      .map((account) => account.unique_id),
  };

  if (accounts.length === 0) {
    return (
      <Modal size={"medium"}>
        <h2>Aucun compte supporté</h2>
        <Card type={"error"} padding={"md"}>
          Aucun compte supporté n'a pu être récupéré. Veuillez contacter le
          support Qalimo.
        </Card>
      </Modal>
    );
  }

  return (
    <Modal size={"medium"}>
      <Formik
        initialValues={initialValues}
        validationSchema={object({ accounts: array().of(string()) })}
        onSubmit={submitHandler}
      >
        {({ values }) => (
          <Form>
            <h2>Comptes récupérés</h2>
            <p>
              Les comptes suivants ont pu être récupérés de votre banque.
              Veuillez sélectionner tous ceux que vous souhaitez synchroniser
              avec Qalimo.
            </p>
            <div className={"flex gap10 wrap marginB20"}>
              {accounts.map((account, i) => (
                <BankCheckbox
                  key={i}
                  bank={account}
                  name={"accounts"}
                  value={account.unique_id}
                />
              ))}
            </div>
            <div className="text-right">
              <FormikSubmitButton disabled={values.accounts.length === 0}>
                Valider
              </FormikSubmitButton>
            </div>
            {synchronizeError && (
              <Card type={"error"} padding={"md"} className={"marginT10"}>
                {synchronizeError}
              </Card>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
